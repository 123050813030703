/** Czech translations. */
export const translations = {

  __localeID: 'cs',

  _dateFormat: 'dd. MM. yy',
  _shortDateFormat: 'dd. MM. yy, HH.mm',
  _mediumDateFormat: 'd. MMMM yyyy, HH.mm',
  _longDateFormat: 'EEEE, d. MMMM yyyy, HH.mm',

  _keyHome: 'Home',
  _keyEnd: 'End',
  _keyShift: '⇧ Shift',
  _keyAlt: 'Alt',
  _keyControl: '✲ Ctrl',

  pageNotFound: 'Požadovaná stránka nebyla nalezena.',

  accountApiAuthKey: 'Autorizační klíč rozhraní API',
  accountAuthorities: 'Oprávnění',
  accountEmail: 'E-mailová adresa',
  accountFirstName: 'Jméno',
  accountLanguage: 'Jazyk',
  accountLastName: 'Příjmení',
  accountPassword: 'Heslo',
  accountPasswordRetype: 'Opakovat heslo',
  accountScopes: 'Sekce',
  accountTitle: 'Oslovení',
  accountTitleMr: 'Pan',
  accountTitleMs: 'Paní',
  accountUsername: 'Uživatelské jméno',

  actions: 'Akce',
  actionCancel: 'Storno',
  actionClose: 'Zavřít',
  actionCollapse: 'Sbalit',
  actionCompare: 'Porovnat',
  actionCreate: 'Vytvořit',
  actionDelete: 'Smazat',
  actionDownload: 'Ke stažení',
  actionDownloadAsZip: 'Stáhnout jako ZIP',
  actionEdit: 'Editovat',
  actionEditHotspots: 'Editace hotspotů',
  actionExpand: 'Rozbalit',
  actionLoad: 'Načíst',
  actionLogIn: 'Přihlásit',
  actionLogOut: 'Odhlásit',
  actionMove: 'Přesunout',
  actionOpenInNewWindow: 'Otevřít v novém okně',
  actionPageMarkerAdd: 'Přidání značek stránek',
  actionPageMarkerRemove: 'Odstranění značek stránek',
  actionPrint: 'Tisk',
  actionQuantityDecrease: 'Snížit počet',
  actionQuantityIncrease: 'Zvýšit počet',
  actionRedo: 'Opakovat',
  actionRelease: 'Publikovat',
  actionRemove: 'Odebrat',
  actionReplace: 'Nahradit',
  actionReset: 'Obnovit',
  actionSave: 'Uložit',
  actionSend: 'Odeslat',
  actionShowAll: 'Zobrazit vše',
  actionUndo: 'Zpět',
  actionUnrelease: 'Nepublikované',
  actionUpload: 'Nahrát',
  actionView: 'Oznámit',

  backgroundSizeCenter: 'Vycentrovat',
  backgroundSizeFill: 'Vyplnit',
  backgroundSizeRepeat: 'Opakovat',
  backgroundSizeStretch: 'Roztáhnout',

  bookmarks: 'Záložka',
  bookmarksAdded: 'Strana {{page}} přidána k záložkám.',
  bookmarksNone: 'Ještě nemáte záložky. Otevřete přehled stránek a klikněte na symbol <x id="START_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;mat-icon&gt;"/>bookmark<x id="CLOSE_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;/mat-icon&gt;"/> pro přidání stránky ke svým záložkám.',
  bookmarksRemoved: 'Strana {{page}} odebrána ze záložek.',

  browserDimensions: 'ngpages není kompatibilní s touto velikostí okna. Změňte velikost okna.',
  browserError1: 'Váš prohlížeč není v současné době podporován službou ngpages.',
  browserError2: 'Použijte jeden z námi podporovaných prohlížečů.',

  config: 'Konfigurace',
  configAnimateCorners: 'Animovat rohy',
  configAnimateHotspots: 'Animovat hotspoty',
  configBackgroundImage: 'Tapety na pozadí',
  configBackgroundSize: 'Velikost pozadí',
  configCornerSize: 'Velikost rohů (px)',
  configCoverPage: 'Titulní stránka (pouze aplikace)',
  configCurrency: 'Měna',
  configCustomCSS: 'Vlastní CSS',
  configCustomJS: 'Vlastní JavaScript',
  configDesign: 'Design',
  configDocumentMargin: 'Mezery dokumentu (px)',
  configDoublePage: 'Dvojité stránky',
  configFeatures: 'Funkce',
  configHideToolbar: 'Skrýt nástrojovou lištu',
  configIndexing: 'Indexování vyhledávacích robotů',
  configLayout: 'Layout',
  configLoader: 'Předem načíst obrázky',
  configMenuPosition: 'Pozice menu',
  configMiscellaneous: 'Další',
  configPageFlipAnimation: 'Listový efekt',
  configPageFlipSound: 'Hluk listí',
  configPageMarkerWidth: 'Šířka značky stránky (px)',
  configPrimaryBackgroundColor: 'Barva pozadí 1',
  configPrimaryForegroundColor: 'Barva textu 1',
  configSecondaryBackgroundColor: 'Barva pozadí 2',
  configSecondaryForegroundColor: 'Barva textu 2',
  configShop: 'Shop',
  configShopArticleRegExp: 'Číslo položky-Regex',
  configShopEndpoint: 'Koncový bod',
  configSidePanelPosition: 'Umístění postranního panelu',
  configToolbarPosition: 'Umístění panelu nástrojů',
  configTracking: 'Tracking',
  configTransparency: 'Průhlednost',
  configVat: 'DPH (%)',
  configVatIncluded: 'včetně DPH',

  download: 'Ke stažení',
  downloadAllPages: 'Všechny stránky',
  downloadPages: 'Stránky',
  downloadPagesPlaceholder: 'např. 1-5, 8, 11-13',
  downloadSelectedPages: 'Vybrané stránky',

  editorInfoSelect: 'Nakreslením obdélníku vyberte hotspoty v oblasti.',
  editorInfoDrawRect: 'Stisknutím levého tlačítka myši začněte kreslit hotspot obdélníku a uvolněním se oblast dokončí.',
  editorInfoDrawCircle: 'Stisknutím levého tlačítka myši začněte kreslit hotspot kruhu a uvolněním se oblast dokončí.',
  editorInfoDrawEllipse: 'Stisknutím levého tlačítka myši začněte kreslit hotspot elipsy a uvolněním se oblast dokončí.',
  editorInfoDrawPolygon1: 'Stisknutím levého tlačítka myši nakreslete nový hotspot mnohoúhelníku nebo přidejte bod k mnohoúhelníku.',
  editorInfoDrawPolygon2: 'Stiskněte pravé tlačítko myši nebo <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>⏎ Enter<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> pro dokončení mnohoúhelníku.',
  editorInfoDraw1: 'Při kreslení hotspotu akci zrušíte stisknutím klávesy <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>Esc<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>.',
  editorInfoDraw2: 'Při kreslení hotspotu můžete podržet <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>⇧ Shift<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> a zarovnat ho s mřížkou.',
  editorInfoDraw3: 'Klepnutím vyberte hotspot.<x id="LINE_BREAK" ctype="lb" equiv-text="&lt;br/&gt;"/>Můžete vybrat více hotspotů tak, že přidržíte stisknuté tlačítko <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> a můžete zvolit všechny hotspoty tak, že stisknete kombinaci <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>A<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>.',
  editorInfoDraw4: 'Vybrané hotspoty zkopírujete pomocí <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>C<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> a přidáte pomocí <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>V<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>.',
  editorInfoDraw5: 'Vybrané hotspoty smažete pomocí <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>Del<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>.',
  editorInfoDelete: 'Klepnutím na hotspot ho smažete.',

  editorGrid: 'Mřížky',
  editorGridColor: 'Barva mřížky',
  editorGridSize: 'Velikost mřížky (px)',
  editorGridVisibility: 'Zobrazit mřížku',
  editorGridSnapToGrid: 'Zarovnání k mřížce',

  editorTools: 'Nástroje',
  editorToolDraw: 'Kreslit',
  editorToolSelect: 'Zvolit',
  editorToolDelete: 'Smazat',

  feedback: 'Zpětná vazba',
  feedbackAdditionalInfo: 'Povězte nám trochu víc (volitelně)',
  feedbackCategoryGeneral: 'Obecné',
  feedbackCategoryUi: 'Uživatelské rozhraní',
  feedbackConfirmation: 'Děkujeme, že jste nám pomohli zdokonalit ngpages!',
  feedbackEmailAddress: 'Vaše e-mailová adresa (nepovinné)',
  feedbackError: 'Váš názor nemohl být odeslán. Opakujte akci později, prosím.',
  feedbackSelectCategory: 'Vybrat kategorii',

  fullscreen: 'Plný obraz',

  help: 'Nápověda',
  helpGestureMenu: 'Tah směrem dolů pro otevření menu a směrem nahoru pro zavření.',
  helpGestureNav: 'Tahem doprava nebo doleva navigujte mezi stránkami.',
  helpGestureZoom: 'Dvojitým poklepáním na obrazovku zvětšete náhled.',
  helpKeyboardShortcuts: 'Klávesová zkratka',
  helpNavigation: 'Navigace',
  helpPanelsAndDialogs: 'Panely & dialogy',
  helpZoom: 'Zoom',

  home: 'Spustit',
  homeIntro: 'Přetáhněte soubor PDF do okna nebo klepněte na tlačítko a vytvořte novou publikaci.',
  homeLetsRumble: 'Jdeme na to!',
  homeRequirements: 'Požadavky na PDF',
  homeWelcome: 'Vítejte na nových ngpages!',

  hotspots: 'Hotspoty',
  hotspotCount: '<x id="INTERPOLATION" equiv-text="{{editor.hotspots.length}}"/> Hotspoty',
  hotspotCountSelected: '<x id="INTERPOLATION" equiv-text="{{editor.selectedHotspots.length}}"/> Vybrané hotspoty',
  hotspotCountCopied: '<x id="INTERPOLATION" equiv-text="{{editor.copiedHotspots.length}}"/> Hotspoty ve schránce',

  hotspotActionTypeArticle: 'Článek',
  hotspotActionTypeAudio: 'Audio',
  hotspotActionTypeExternal: 'Externí odkaz',
  hotspotActionTypeFeature: 'Funkce',
  hotspotActionTypeGallery: 'Galerie',
  hotspotActionTypeIframe: 'iFrame',
  hotspotActionTypeImage: 'Obrázek',
  hotspotActionTypeInfo: 'Info',
  hotspotActionTypeInternal: 'Interní odkaz',
  hotspotActionTypeMulti: 'Různé',
  hotspotActionTypeNone: 'Vyberte prosím',
  hotspotActionTypeVideo: 'Video',

  hotspotDisplayTypeBorder: 'Rámeček',
  hotspotDisplayTypeBox: 'Pole',
  hotspotDisplayTypeHidden: 'Skryté',
  hotspotDisplayTypeHover: 'Hover',
  hotspotDisplayTypeIcon: 'Ikona',
  hotspotDisplayTypeInline: 'Inline',
  hotspotDisplayTypeMulti: 'Různé',
  hotspotDisplayTypeNone: 'Vyberte prosím',

  hotspotProperties: 'Vlastnosti hotspotu',
  hotspotPropertyType: 'Typ',
  hotspotPropertyActionInternal: 'Číslo stránky',
  hotspotPropertyActionExternal: 'URL',
  hotspotPropertyActionVideo: 'URL (MP4, WebM, Ogg, YouTube, Vimeo, Dailymotion)',
  hotspotPropertyActionAudio: 'URL (MP3, WAV, Ogg)',
  hotspotPropertyActionArticle: 'Čís. položky',
  hotspotPropertyActionDefault: 'Akce',
  hotspotPropertyTitle: 'Titul',
  hotspotPropertyDescription: 'Popis',
  hotspotPropertyDisplayType: 'Typ prezentace',
  hotspotPropertyColor: 'Barva',
  hotspotPropertyAutoplay: 'Automatické přehrávání',
  hotspotPropertyPrice: 'Cena',
  hotspotPropertyPosition: 'Pozice',
  hotspotPropertyPositionX: 'X (px)',
  hotspotPropertyPositionY: 'Y (px)',
  hotspotPropertyDimensions: 'Rozměry',
  hotspotPropertyDimensionWidth: 'Šířka (px)',
  hotspotPropertyDimensionHeight: 'Výška (px)',

  hotspotShapes: 'Formuláře',
  hotspotShapeRect: 'Obdélník',
  hotspotShapeCircle: 'Kruh',
  hotspotShapeEllipse: 'Elipsa',
  hotspotShapePolygon: 'Mnohoúhelník',

  info: 'Info',
  infoCopyright1: 'Copyright © 2019 wissenswerft GmbH',
  infoCopyright2: 'ngpages jsou produktem společnosti wissenswerft GmbH.',
  infoTechnicalQuestions: 'Máte-li nějaké technické dotazy, neváhejte nás kontaktovat na adrese <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>support@page2flip.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',
  infoVisitUs: 'Navštivte náš web na adrese <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>www.page2flip.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/> nebo <x id="START_LINK_1" ctype="x-a" equiv-text="&lt;a&gt;"/>www.wissenswerft.net<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',

  legal: 'Právní ujednání',
  legalImprint: 'Tiráž',
  legalPrivacyPolicy: 'Zásady ochrany osobních údajů',
  legalTermsOfService: 'Podmínky používání',

  loading: 'Nahrává se...',
  loadingPublication: 'Nahrávám publikaci...',
  loadingPreview: 'Nahrávám náhled...',

  loginInstance: 'space.one-instance',
  loginUsername: 'Uživatelské jméno',
  loginPassword: 'Heslo',
  loginStayLoggedIn: 'Zůstat přihlášený',

  menu: 'Menu',

  navigationFirstPage: 'První strana',
  navigationLastPage: 'Poslední strana',
  navigationNextPage: 'Další strana',
  navigationPage: 'Strana',
  navigationPageBack: 'O stranu zpět',
  navigationPreviousPage: 'Předchozí strana',

  notes: 'Poznámky',
  notesNone: 'Ještě nemáte žádné poznámky. Otevřete přehled stran a klikněte na symbol <x id="START_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;mat-icon&gt;"/>comment<x id="CLOSE_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;/mat-icon&gt;"/> pro přidání poznámek ke stránce.',
  notesPlaceholder: 'Vaše poznámky',
  notesRemoved: 'Odebrány poznámky pro stranu {{page}}.',
  notesSaved: 'Uloženy poznámky pro stranu {{page}}.',
  notesTitle: 'Poznámky pro stranu <x id="INTERPOLATION" equiv-text="{{pageNumber}}"/>',

  offlineAvailability: 'Dostupnost offline (pouze v aplikacích)',

  pageComparison: 'Boční porovnání',
  pageComparisonError: 'Zadejte prosím platné číslo stránky.',
  pageComparisonLeftPage: 'Levá strana',
  pageComparisonRightPage: 'Pravá strana',
  pageComparisonWarning: 'Porovnávání stránek není k dispozici, pokud je efekt listů aktivní.',

  pageFlipAnimationHint: 'Efekt listu je k dispozici pouze v zobrazení pracovní plochy.',
  pageFlipAnimationWarning: 'Efekt listu není k dispozici, pokud je porovnávání stránek aktivní.',

  pageOverview: 'Přehled stránek',
  leftPage: 'Levá strana',
  rightPage: 'Pravá strana',

  pageMarkers: 'Boční značky',
  pageMarkerColor: 'Barva značky',
  pageMarkerLabel: 'Označení',
  pageMarkerLabelColor: 'Text',
  pageMarkerNew: 'Nová značka stránky',
  pageMarkerPage: 'Strana',
  pageMarkerWarning: 'Značky stránek nejsou k dispozici, pokud je efekt listu aktivní.',

  paginatorItemsPerPageLabel: 'Položky na stránce:',
  paginatorNextPageLabel: 'Další strana',
  paginatorPreviousPageLabel: 'Předchozí strana',
  paginatorFirstPageLabel: 'První strana',
  paginatorLastPageLabel: 'Poslední strana',
  paginatorRangeOfLabel: 'z',

  positionBottom: 'Dolů',
  positionLeft: 'Vlevo',
  positionRight: 'Vpravo',
  positionTop: 'Nahoru',

  publication: 'Publikace',
  publications: 'Publikace',
  publicationsLatest: 'Vaše nejnovější publikace',
  publicationAuthor: 'Autor',
  publicationCover: 'Cover',
  publicationDateCreated: 'Vytvořeno',
  publicationDateModified: 'Upraveno',
  publicationDatePublished: 'Zveřejněno',
  publicationDelete: 'Smazat publikaci',
  publicationDeleteMessage: 'Opravdu chcete smazat tuto publikaci? Pozor, tuto akci nelze vrátit zpět.',
  publicationDetails: 'Podrobnosti',
  publicationDetailsEdit: 'Upravit podrobnosti',
  publicationDescription: 'Popis',
  publicationHotspots: 'Hotspoty',
  publicationKeywords: 'Klíčová slova',
  publicationLanguage: 'Jazyk',
  publicationPages: 'Stránky',
  publicationTitle: 'Titul',

  releaseApp: 'Aplikace',
  releaseDownload: 'Ke stažení',
  releaseOnline: 'Online',
  releasePreview: 'Náhled',

  search: 'Hledat',
  searchClear: 'Odstranit hledaný termín',
  searchFoundMatches: 'Bylo nalezeno <x id="INTERPOLATION" equiv-text="{{searchResults.length}}"/> stránek.',
  searchNoMatches: 'Nebyly nalezeny žádné stránky. Zadejte alespoň 3 znaky.',
  searchMatches: '<x id="INTERPOLATION" equiv-text="{{searchResult.matches}}"/> výsledky',
  searchPlaceholder: 'Co hledáš?',

  settings: 'Nastavení',

  share: 'Rozdělit',
  shareCurrentPage: 'Sdílet aktuální stránku',
  shareSocialMedia: 'Sociální média',
  shareUrl: 'URL',

  shopAdditionalInfo: 'Další informace ✎',
  shopItemNumber: 'Číslo pol.',
  shopVatExcluded: 'bez {{vat}}% DPH',
  shopVatIncluded: 'včet. {{vat}}% DPH',

  shoppingCart: 'Nákupní koš',
  shoppingCartAdd: 'Přidat do nákupního koše',
  shoppingCartAdded: '{{title}} přidán do nákupního koše.',
  shoppingCartClear: 'Vyprázdnit nákupní koš',
  shoppingCartCleared: 'Nákupní koš vyprázdněn.',
  shoppingCartEmpty: 'V nákupním koši ještě nemáte žádné zboží.',
  shoppingCartPayNow: 'Zaplaťte nyní',
  shoppingCartRemoved: '{{title}} z nákupního koše odebrán.',
  shoppingCartSum: 'Součet',

  tableOfContents: 'Seznam obsahu',
  tableOfContentsToggle: 'Schovat/zobrazit všechny položky',

  uploaderConfirm: 'Chcete tento soubor uložit?',
  uploaderErrorFileCount: 'V jednom okamžiku můžete nahrát pouze jeden soubor PDF.',
  uploaderErrorFileType: 'Můžete nahrávat pouze soubory PDF.',
  uploaderFileDate: 'Datum změny: <x id="INTERPOLATION" equiv-text="{{file.lastModified | date:dateFormat}}"/>',
  uploaderFileDrop: 'Zde odložte svůj soubor PDF.',
  uploaderFileSize: 'Velikost souboru: <x id="INTERPOLATION" equiv-text="{{file.size / 1024 / 1024 | number}}"/> MB',
  uploaderUploading: 'Vyčkejte prosím, než bude váš soubor odeslán.',

  viewerPreview: 'Náhled',
  viewerPreviewDesktop: 'Desktop',
  viewerPreviewPhone: 'Smartphone',
  viewerPreviewTablet: 'Tablet',
  viewerPreviewWarning: 'Náhled se může lišit od skutečného vzhledu.',

  watchList: 'Seznam k zapamatování',
  watchListAdd: 'Přidat k seznamu k zapamatování',
  watchListAdded: '{{title}} přidáno k seznamu k zapamatování.',
  watchListClear: 'Odstranit seznam k zapamatování',
  watchListCleared: 'Seznam k zapamatování smazán.',
  watchListEmpty: 'Ještě nemáte žádné položky ve svém seznamu k zapamatování.',
  watchListExists: '{{title}} je již na seznamu k zapamatování.',
  watchListRemoved: '{{title}} ze seznamu k zapamatování odebrán.',

  wizardAnalysis: 'Hodnocení',
  wizardAnalysisDescription: 'Statistika & postřehy',
  wizardDocument: 'Dokument',
  wizardDocumentDescription: 'Přehled & metadata',
  wizardHotspots: 'Hotspoty',
  wizardHotspotsDescription: 'Vytvořit & úpravy',
  wizardPublication: 'Publikace',
  wizardPublicationDescription: 'Publikace & sdílení',
  wizardViewer: 'Viewer',
  wizardViewerDescription: 'Konfigurace & náhled',

  zoom: 'Zoom',
  zoomError: 'Chcete-li zvětšit zobrazení, poklepejte dvakrát na obrazovku.',
  zoomIn: 'Velmi zvětšit',
  zoomJumpBottom: 'Skočit dolů',
  zoomJumpLeft: 'Skočit doleva',
  zoomJumpRight: 'Skočit doprava',
  zoomJumpTop: 'Skočit nahoru',
  zoomMoveDown: 'Posunout dolů',
  zoomMoveLeft: 'Posunout doleva',
  zoomMoveRight: 'Posunout doprava',
  zoomMoveTop: 'Posunout nahoru',
  zoomOut: 'Zmenšit zvětšení',
  zoomReset: 'Obnovit zvětšení',

  // vw-workshop-equipment specific translations

  vw_actionCreateCatalog: 'Vytvořit katalog',
  vw_actionCreateNewCatalog: 'Vytvořit nový katalog',
  vw_actionReset: 'Začít od začátku',
  vw_actionDownloadCatalog: 'Stáhnout katalog',
  vw_catalogLanguage: 'Jazyk katalogu',
  vw_catalogPreview: 'Náhled katalogu',
  vw_catalogCurrency: 'Měna',
  vw_imprint: 'Tiráž',
  vw_intro1: 'Vítejte v tvůrci prodejního katalogu Vybavení díly pro Volkswagen AG. Zde můžete v několika krocích snadno vytvořit specifický katalog pro příslušný trh. K těmto účelům doporučujeme používat prohlížeče Edge, Firefox nebo Chrome.',
  vw_intro2: 'Je to snadné:',
  vw_intro3: 'Po cca 30 minutách je proces vytváření u konce a můžete procházet katalog, stahovat jej jako balíček ZIP nebo restartovat proces.',
  vw_intro4: 'Máte-li jakékoli dotazy týkající se katalogu, přání nebo nápadů na zlepšení, těšíme se na váš názor na <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>Workshop.Equipment@volkswagen.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',
  vw_intro_list1: 'Stáhněte šablonu ceníku do svého PC. Jedná se o soubor CSV s kompletním rozsahem produktů zahrnutých do katalogu, specifikací nákupní ceny obchodníka a UPE, které jsou v souladu se strategií, a normami EU pro značky koncernu. Produkty s přímou fakturací lze nalézt v seznamu s pokynem „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>on request<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“.',
  vw_intro_list11: '<x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>Stažení šablony ceníku (stav: ceny k 01.01.2021, Standardy 01.01.2021)<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>',
  vw_intro_list2: 'Otevřete soubor CSV v aplikaci Excel.',
  vw_intro_list3: 'Zadejte ceny ve sloupcích nákupní ceny obchodníka a UPE pro specifický trh. Pokud nechcete uvést cenu nebo nabízet produkt na svém trhu, můžete jednoduše do pole pro cenu zapsat „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>on request<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“ nebo „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>not available<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“.',
  vw_intro_list4: 'Přidejte informace o standardu ve sloupcích VW, Audi, SEAT, ŠKODA, VWN a Porsche. Zadejte tam prosím „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>0<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“, pokud výrobek není u odpovídající značky standardem nebo zadejte „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>1<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“, pokud ke standardu patří.',
  vw_intro_list5: 'Neměňte žádné položky ve sloupcích „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Product<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“, a také „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Article-number<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“, aby byla zajištěna plynulá funkčnost.. Neodstraňujte ani nepřidávejte sloupce či řádky ani neměňte záhlaví sloupců.',
  vw_intro_list6: 'Uložte si svůj ceník jako soubor CSV do svého lokálního PC.',
  vw_intro_list7: 'Nahrajte svůj ceník opět pomocí dole umístěného tlačítka „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Vybrat soubor<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“ nebo přetažením souboru CSV do šedého pole.',
  vw_intro_list8: 'Zadejte informace o titulní stránce: svůj trh, své nacenění (nákupní cena obchodníka nebo UPE) a stav ceny (datum platnosti informací o ceně).',
  vw_intro_list9: 'Vyberte jazyk katalogu.',
  vw_intro_list10: 'Spusťte proces vytváření pomocí tlačítka „<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Vytvořit katalog<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>“',
  vw_legalNotice: 'Právní ujednání',
  vw_loginError: 'Chybné heslo.',
  vw_loginMessage: 'Chcete-li pokračovat, přihlaste se.',
  vw_market: 'Trh',
  vw_priceDate: 'Stav cen',
  vw_priceList: 'Ceník',
  vw_priceType: 'Ceny',
  vw_priceTypeDNP: 'DNP',
  vw_priceTypeRRP: 'RRP',
  vw_stateConverting: 'Váš katalog se vytváří. To může nějakou dobu trvat.',
  vw_stateFailed: 'Došlo k chybě. Opakujte akci.',
  vw_stateUploading: 'Data jsou odesílána. Počkejte chvíli, než proces vytváření začne.',
  vw_stateWaiting: 'Katalog byl přidán do fronty.',
  vw_title: 'Workshop Equipment Catalogue Creator',
  vw_uploaderMsgClickOrDragFile: 'nebo soubor CSV.',
  vw_uploaderMsgDropFile: 'Zde uložte soubor.',
  vw_uploaderMsgFileCountError: 'Můžete nahrát pouze jeden soubor.',
  vw_uploaderMsgFileTypeError: 'Můžete nahrávat pouze soubory CSV.'

};
