import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ConverterState } from '@page2flip/core/common';
import { UploadService } from '../upload/upload.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WINDOW } from '@page2flip/core';

@Component({
  selector: 'vw-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})
export class CatalogComponent implements OnInit {

  mediaId: String;

  submediaId: String;

  /** Possible converter states. */
  state: ConverterState;

  /** Converter progress in %. */
  progress = 0;

  errorMessage: string;

  /** Catalog preview URL. */
  previewUrl: SafeResourceUrl;

  /** Catalog download URL. */
  downloadUrl: SafeResourceUrl;

  /** Whether or not to show the preview. */
  preview = false;

  /** Location of the sub-medium. */
  location: string;

  /** ZIP file name. */
  readonly filename = 'WK.zip'; // TODO: change

  /** Whether or not the user is authenticated. */
  authenticated = false;

  constructor(
    @Inject(WINDOW) private window: any,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    public uploader: UploadService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.mediaId = params['mediaId'];
      this.submediaId = params['submediaId'];
      this.checkStatus();
    });
  }

  /**
   * Checks the converter status.
   *
   * @param url Sub-medium location.
   */
  private checkStatus() {
    const url: string = this.uploader.createCheckUrl(this.mediaId, this.submediaId);
    const statusInterval = setInterval(() => {
      this.uploader
        .checkStatus(url)
        .subscribe((statusResponse: HttpResponse<any>) => {
          this.preview = false;
          this.previewUrl = undefined;
          this.downloadUrl = undefined;

          this.state = statusResponse.body.state.toUpperCase();
          this.progress = statusResponse.body.percentageState;
          this.window.localStorage.setItem('state', this.state);

          if (this.state === ConverterState.Ready) {
            clearInterval(statusInterval);

            this.preview = true;
            this.location = url;
            this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.location
                .replace('api/medium', 'preview')
                .replace('submedium', 'submedia')
            );
            this.downloadUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.location + '/download?filename=' + this.filename
            );
          } else if(this.state === ConverterState.Failed) {
            this.errorMessage = statusResponse.body;
          }
        },
          (error: HttpErrorResponse) => {
            clearInterval(statusInterval);
            if(error.status === 404) {
              this.state = ConverterState.Ready;
              this.previewUrl = this.uploader.createPreviewUrl(this.mediaId, this.submediaId);
              this.preview = true;
            } else {
              this.state = ConverterState.Failed;
              this.window.localStorage.setItem('state', this.state);
              this.errorMessage = error.error;
            }
          });
    }, 2500);
  }

  /**
   * Resets the uploader.
   */
  reset() {
    this.window.localStorage.removeItem('location');
    this.window.localStorage.removeItem('state');
    this.router.navigate(['/upload']);
  }
}
