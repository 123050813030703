/** Chinese translations. */
export const translations = {

  __localeID: 'zh',

  _dateFormat: 'yy-MM-dd',
  _shortDateFormat: 'yy-MM-dd, HH:mm',
  _mediumDateFormat: 'yyyy年MMMM月d日, HH:mm',
  _longDateFormat: 'yyyy年MMMM月d日EEEE, HH:mm',

  _keyHome: 'Home（起始）',
  _keyEnd: 'End（末尾）',
  _keyShift: '⇧ Shift',
  _keyAlt: 'Alt',
  _keyControl: '✲ Ctrl',

  pageNotFound: '找不到您请求的页面。',

  accountApiAuthKey: 'API 授权密钥',
  accountAuthorities: '权限',
  accountEmail: '电子邮件地址',
  accountFirstName: '名字',
  accountLanguage: '语言',
  accountLastName: '姓',
  accountPassword: '密码',
  accountPasswordRetype: '重复密码',
  accountScopes: '地区',
  accountTitle: '称谓',
  accountTitleMr: '先生',
  accountTitleMs: '女士',
  accountUsername: '用户名',

  actions: '行动',
  actionCancel: '中断',
  actionClose: '关闭',
  actionCollapse: '隐藏',
  actionCompare: '对比',
  actionCreate: '新建',
  actionDelete: '删除',
  actionDownload: '下载',
  actionDownloadAsZip: '下载为 ZIP',
  actionEdit: '编辑',
  actionEditHotspots: '编辑热点',
  actionExpand: '展开',
  actionLoad: '加载',
  actionLogIn: '登录',
  actionLogOut: '退出',
  actionMove: '移动',
  actionOpenInNewWindow: '在新窗口中打开',
  actionPageMarkerAdd: '添加页面标记',
  actionPageMarkerRemove: '删除页面标记',
  actionPrint: '打印',
  actionQuantityDecrease: '减少数量',
  actionQuantityIncrease: '增加数量',
  actionRedo: '重试',
  actionRelease: '发布',
  actionRemove: '移除',
  actionReplace: '替换',
  actionReset: '重置',
  actionSave: '保存',
  actionSend: '发送',
  actionShowAll: '查看所有',
  actionUndo: '撤消',
  actionUnrelease: '未发布',
  actionUpload: '上传',
  actionView: '查看',

  backgroundSizeCenter: '居中',
  backgroundSizeFill: '填写',
  backgroundSizeRepeat: '重试',
  backgroundSizeStretch: '伸展',

  bookmarks: '书签',
  bookmarksAdded: '将页面 {{page}} 添加到书签。',
  bookmarksNone: '您还没有书签。打开页面概览并单击 <x id="START_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;mat-icon&gt;"/>bookmark<x id="CLOSE_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;/mat-icon&gt;"/> 图标以将页面添加到书签中。',
  bookmarksRemoved: '已删除书签页 {{page}}。',

  browserDimensions: 'ngpages 与该窗口大小不兼容。请更改窗口大小。',
  browserError1: '当前，ngpages 不支持您的浏览器。',
  browserError2: '请使用我们支持的浏览器之一。',

  config: '配置',
  configAnimateCorners: '动画角',
  configAnimateHotspots: '动画热点',
  configBackgroundImage: '壁纸',
  configBackgroundSize: '背景大小',
  configCornerSize: '角大小 (px)',
  configCoverPage: '封面（仅限应用程序）',
  configCurrency: '货币',
  configCustomCSS: '自定义 CSS',
  configCustomJS: '自定义 JavaScript',
  configDesign: '设计',
  configDocumentMargin: '文档间距 (px)',
  configDoublePage: '双页',
  configFeatures: '专题',
  configHideToolbar: '隐藏工具栏',
  configIndexing: '搜索引擎索引',
  configLayout: '布局',
  configLoader: '预加载图片',
  configMenuPosition: '菜单位置',
  configMiscellaneous: '其他',
  configPageFlipAnimation: '翻页效果',
  configPageFlipSound: '翻页声音',
  configPageMarkerWidth: '页面标记宽度 (px)',
  configPrimaryBackgroundColor: '背景色 1',
  configPrimaryForegroundColor: '文本颜色 1',
  configSecondaryBackgroundColor: '背景色 2',
  configSecondaryForegroundColor: '文本颜色 2',
  configShop: '商店',
  configShopArticleRegExp: '商品编号正则表达式',
  configShopEndpoint: '终端',
  configSidePanelPosition: '侧面板位置',
  configToolbarPosition: '工具栏位置',
  configTracking: '跟踪',
  configTransparency: '透明度',
  configVat: '增值税 (%)',
  configVatIncluded: '包括增值税',

  download: '下载',
  downloadAllPages: '所有页面',
  downloadPages: '页面',
  downloadPagesPlaceholder: '例如 1-5、8、11-13',
  downloadSelectedPages: '所选页面',

  editorInfoSelect: '绘制一个矩形以选择其中的热点。',
  editorInfoDrawRect: '按鼠标左键开始绘制矩形热点并将其释放以完成热点。',
  editorInfoDrawCircle: '按鼠标左键开始绘制圆形热点并将其释放以完成热点。',
  editorInfoDrawEllipse: '按鼠标左键开始绘制椭圆形热点并将其释放以完成热点。',
  editorInfoDrawPolygon1: '按鼠标左键可绘制新的多边形热点或向多边形添加点。',
  editorInfoDrawPolygon2: '要完成多边形，请按鼠标右键或 <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>⏎ Enter<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>。',
  editorInfoDraw1: '绘制热点时，按 <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>Esc<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> 取消。',
  editorInfoDraw2: '绘制热点时，可以按住 <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>⇧ Shift<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> 使其与网格对齐。',
  editorInfoDraw3: '单击热点以将其选中。<x id="LINE_BREAK" ctype="lb" equiv-text="&lt;br/&gt;"/>您可以按住 <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> 选择多个热点，并且可以通过按 <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>A<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> 来选择所有热点。',
  editorInfoDraw4: '您可以使用 <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>C<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> 复制选定的热点，并使用 <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>V<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> 粘贴。',
  editorInfoDraw5: '按 <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>Del<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> 删除所选热点。',
  editorInfoDelete: '单击热点将其删除。',

  editorGrid: '网格',
  editorGridColor: '网格颜色',
  editorGridSize: '网格大小 (px)',
  editorGridVisibility: '显示网格',
  editorGridSnapToGrid: '对齐到网格',

  editorTools: '工具',
  editorToolDraw: '绘制',
  editorToolSelect: '选择',
  editorToolDelete: '删除',

  feedback: '反馈',
  feedbackAdditionalInfo: '告诉我们更多（可选）',
  feedbackCategoryGeneral: '一般',
  feedbackCategoryUi: '用户界面',
  feedbackConfirmation: '感谢您帮助我们改进 ngpages！',
  feedbackEmailAddress: '您的电子邮件地址（可选）',
  feedbackError: '无法发送您的反馈。请稍后再试。',
  feedbackSelectCategory: '选择类别',

  fullscreen: '全屏',

  help: '帮助',
  helpGestureMenu: '向下轻扫可打开菜单，然后向上轻扫以将其关闭。',
  helpGestureNav: '向右或向左轻扫即可在页面之间导航。',
  helpGestureZoom: '双击屏幕以放大视图。',
  helpKeyboardShortcuts: '快捷键',
  helpNavigation: '导航',
  helpPanelsAndDialogs: '面板和对话',
  helpZoom: '缩放',

  home: '开始',
  homeIntro: '将 PDF 文件拖入窗口中或单击按钮创建新发布。',
  homeLetsRumble: '开始吧！',
  homeRequirements: 'PDF 的要求',
  homeWelcome: '欢迎来到全新的 ngpages！',

  hotspots: '热点',
  hotspotCount: '<x id="INTERPOLATION" equiv-text="{{editor.hotspots.length}}"/> 热点',
  hotspotCountSelected: '<x id="INTERPOLATION" equiv-text="{{editor.selectedHotspots.length}}"/> 已选择热点',
  hotspotCountCopied: '<x id="INTERPOLATION" equiv-text="{{editor.copiedHotspots.length}}"/> 剪贴板上的热点',

  hotspotActionTypeArticle: '文章',
  hotspotActionTypeAudio: '音频',
  hotspotActionTypeExternal: '外部链接',
  hotspotActionTypeFeature: '专题',
  hotspotActionTypeGallery: '库',
  hotspotActionTypeIframe: 'iFrame',
  hotspotActionTypeImage: '图像',
  hotspotActionTypeInfo: '信息',
  hotspotActionTypeInternal: '内部链接',
  hotspotActionTypeMulti: '各种',
  hotspotActionTypeNone: '请选择',
  hotspotActionTypeVideo: '视频',

  hotspotDisplayTypeBorder: '边框',
  hotspotDisplayTypeBox: '盒',
  hotspotDisplayTypeHidden: '隐藏',
  hotspotDisplayTypeHover: '悬停',
  hotspotDisplayTypeIcon: '图标',
  hotspotDisplayTypeInline: '内嵌',
  hotspotDisplayTypeMulti: '各种',
  hotspotDisplayTypeNone: '请选择',

  hotspotProperties: '热点属性',
  hotspotPropertyType: '类型',
  hotspotPropertyActionInternal: '页码',
  hotspotPropertyActionExternal: '网址',
  hotspotPropertyActionVideo: '网址（MP4、WebM、Ogg、YouTube、Vimeo、Dailymotion）',
  hotspotPropertyActionAudio: '网址（MP3、WAV、Ogg）',
  hotspotPropertyActionArticle: '商品编号',
  hotspotPropertyActionDefault: '行动',
  hotspotPropertyTitle: '标题',
  hotspotPropertyDescription: '描述',
  hotspotPropertyDisplayType: '演示文稿类型',
  hotspotPropertyColor: '颜色',
  hotspotPropertyAutoplay: '自动播放',
  hotspotPropertyPrice: '价格',
  hotspotPropertyPosition: '位置',
  hotspotPropertyPositionX: 'X (px)',
  hotspotPropertyPositionY: 'Y (px)',
  hotspotPropertyDimensions: '尺寸',
  hotspotPropertyDimensionWidth: '宽度 (px)',
  hotspotPropertyDimensionHeight: '高度 (px)',

  hotspotShapes: '形状',
  hotspotShapeRect: '矩形',
  hotspotShapeCircle: '圆形',
  hotspotShapeEllipse: '椭圆',
  hotspotShapePolygon: '多边形',

  info: '信息',
  infoCopyright1: '版权所有 © 2019 wissenswerft GmbH',
  infoCopyright2: 'ngpages 是 wissenswerft GmbH 的产品。',
  infoTechnicalQuestions: '如果您有任何技术问题，请随时通过 <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>support@page2flip.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/> 联系我们。',
  infoVisitUs: '请访问我们的网站 <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>www.page2flip.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/> 或 <x id="START_LINK_1" ctype="x-a" equiv-text="&lt;a&gt;"/>www.wissenswerft.net<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>。',

  legal: '法律',
  legalImprint: '公司信息',
  legalPrivacyPolicy: '隐私政策',
  legalTermsOfService: '使用条款',

  loading: '正在加载...',
  loadingPublication: '正在加载发布...',
  loadingPreview: '正在加载预览...',

  loginInstance: 'space.one instance',
  loginUsername: '用户名',
  loginPassword: '密码',
  loginStayLoggedIn: '保持登录状态',

  menu: '菜单',

  navigationFirstPage: '第一页',
  navigationLastPage: '最后一页',
  navigationNextPage: '下一页',
  navigationPage: '页',
  navigationPageBack: '后退一页',
  navigationPreviousPage: '上一页',

  notes: '笔记',
  notesNone: '您还没有笔记。打开页面概览并单击 <x id="START_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;mat-icon&gt;"/>comment<x id="CLOSE_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;/mat-icon&gt;"/> 图标以向页面添加笔记。',
  notesPlaceholder: '您的笔记',
  notesRemoved: '已删除页面 {{page}} 的笔记。',
  notesSaved: '已保存页面 {{page}} 的笔记。',
  notesTitle: '页面 <x id="INTERPOLATION" equiv-text="{{pageNumber}}"/> 的笔记',

  offlineAvailability: '脱机可用性（仅限应用程序）',

  pageComparison: '页面比较',
  pageComparisonError: '请输入有效的页码。',
  pageComparisonLeftPage: '左侧',
  pageComparisonRightPage: '右侧',
  pageComparisonWarning: '启用翻页效果时，页面比较不可用。',

  pageFlipAnimationHint: '翻页效果仅在桌面视图中可用。',
  pageFlipAnimationWarning: '启用页面比较时，翻页效果不可用。',

  pageOverview: '页面概览',
  leftPage: '左侧',
  rightPage: '右侧',

  pageMarkers: '页面标记',
  pageMarkerColor: '标记颜色',
  pageMarkerLabel: '标签',
  pageMarkerLabelColor: '文本颜色',
  pageMarkerNew: '新页面标记',
  pageMarkerPage: '页',
  pageMarkerWarning: '启用翻页效果时，页面标记不可用。',

  paginatorItemsPerPageLabel: '每页条目数：',
  paginatorNextPageLabel: '下一页',
  paginatorPreviousPageLabel: '上一页',
  paginatorFirstPageLabel: '第一页',
  paginatorLastPageLabel: '最后一页',
  paginatorRangeOfLabel: '从',

  positionBottom: '向下',
  positionLeft: '向左',
  positionRight: '向右',
  positionTop: '向上',

  publication: '发布',
  publications: '发布',
  publicationsLatest: '您最新的发布',
  publicationAuthor: '作者',
  publicationCover: '封面',
  publicationDateCreated: '已创建',
  publicationDateModified: '已编辑',
  publicationDatePublished: '已发布',
  publicationDelete: '删除发布',
  publicationDeleteMessage: '是否确实要删除此发布？请注意，无法撤消此操作。',
  publicationDetails: '详细信息',
  publicationDetailsEdit: '编辑详细信息',
  publicationDescription: '描述',
  publicationHotspots: '热点',
  publicationKeywords: '关键词',
  publicationLanguage: '语言',
  publicationPages: '页面',
  publicationTitle: '标题',

  releaseApp: '应用程序',
  releaseDownload: '下载',
  releaseOnline: '在线',
  releasePreview: '预览',

  search: '搜索',
  searchClear: '删除搜索词',
  searchFoundMatches: '找到页面 <x id="INTERPOLATION" equiv-text="{{searchResults.length}}"/>。',
  searchNoMatches: '未找到页面。输入至少 3 个字符。',
  searchMatches: '<x id="INTERPOLATION" equiv-text="{{searchResult.matches}}"/> 结果',
  searchPlaceholder: '您在找什么？',

  settings: '设置',

  share: '共享',
  shareCurrentPage: '共享当前页面',
  shareSocialMedia: '社交媒体',
  shareUrl: '网址',

  shopAdditionalInfo: '其他信息✎',
  shopItemNumber: '商品编号',
  shopVatExcluded: '不包括 {{vat}}% 增值税',
  shopVatIncluded: '包括 {{vat}}% 增值税',

  shoppingCart: '购物车',
  shoppingCartAdd: '添加到购物车',
  shoppingCartAdded: '已添加 {{title}} 到购物车。',
  shoppingCartClear: '清空购物车',
  shoppingCartCleared: '已清空购物车',
  shoppingCartEmpty: '您的购物车中尚未有任何商品。',
  shoppingCartPayNow: '立即付款',
  shoppingCartRemoved: '已从购物车中删除 {{title}}。',
  shoppingCartSum: '总额',

  tableOfContents: '目录',
  tableOfContentsToggle: '展开/隐藏所有条目',

  uploaderConfirm: '是否要上传此文件？',
  uploaderErrorFileCount: '您一次只能上传一个 PDF 文件。',
  uploaderErrorFileType: '您只能上传 PDF 文件。',
  uploaderFileDate: '修改日期：<x id="INTERPOLATION" equiv-text="{{file.lastModified | date:dateFormat}}"/>',
  uploaderFileDrop: '将 PDF 文件保存在此处。',
  uploaderFileSize: '文件大小：<x id="INTERPOLATION" equiv-text="{{file.size / 1024 / 1024 | number}}"/> MB',
  uploaderUploading: '正在上传您的文件，请稍候。',

  viewerPreview: '预览',
  viewerPreviewDesktop: '桌面',
  viewerPreviewPhone: '智能手机',
  viewerPreviewTablet: '平板电脑',
  viewerPreviewWarning: '预览可能与实际显示不同。',

  watchList: '关注列表',
  watchListAdd: '添加到关注列表',
  watchListAdded: '{{title}} 已添加到关注列表。',
  watchListClear: '删除关注列表',
  watchListCleared: '已删除关注列表。',
  watchListEmpty: '您的关注列表中尚未列出任何商品。',
  watchListExists: '{{title}} 已在关注列表中。',
  watchListRemoved: '已从关注列表中删除 {{title}}。',

  wizardAnalysis: '评价',
  wizardAnalysisDescription: '统计与洞察',
  wizardDocument: '文档',
  wizardDocumentDescription: '概览和摘要数据',
  wizardHotspots: '热点',
  wizardHotspotsDescription: '创建和编辑',
  wizardPublication: '发布',
  wizardPublicationDescription: '发布和共享',
  wizardViewer: 'Viewer',
  wizardViewerDescription: '配置和预览',

  zoom: '缩放',
  zoomError: '双击屏幕以进行缩放。',
  zoomIn: '放大',
  zoomJumpBottom: '向下跳',
  zoomJumpLeft: '向左跳',
  zoomJumpRight: '向右跳',
  zoomJumpTop: '向上跳',
  zoomMoveDown: '向下移动',
  zoomMoveLeft: '向左移动',
  zoomMoveRight: '向右移动',
  zoomMoveTop: '向上移动',
  zoomOut: '缩小',
  zoomReset: '重置缩放',

  // vw-workshop-equipment specific translations

  vw_actionCreateCatalog: '创建目录',
  vw_actionCreateNewCatalog: '创建新目录',
  vw_actionReset: '从头开始',
  vw_actionDownloadCatalog: '下载目录',
  vw_catalogLanguage: '目录语言',
  vw_catalogPreview: '目录预览',
  vw_catalogCurrency: '货币',
  vw_imprint: '公司信息',
  vw_intro1: '欢迎来到大众汽车股份公司车间设备销售目录创建工具。在这里，只需几个步骤，即可轻松创建特定市场的目录。顺便说一下，我们建议为此使用浏览器 Edge、Firefox 或 Chrome。',
  vw_intro2: '简单如下：',
  vw_intro3: '大约 30 分钟后，创建过程就完成了，您可以浏览目录、将其下载为 ZIP 包或重启创建过程。',
  vw_intro4: '如果您对目录有疑问、期望或改进想法，我们期待收到您的反馈，联系方式为 <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>Workshop.Equipment@volkswagen.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>。',
  vw_intro_list1: '将价目表模板下载到您的电脑。这是一个 CSV 文件，包含目录中包括的完整产品范围、符合战略的经销商进货价和非强制建议零售价规范以及集团品牌的欧盟标准。直接开票的产品可在列表中找到，并注明 “<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>on request<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>”。',
  vw_intro_list11: '<x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>下载价目表模板（状态：2021.01.01 价格，2021.01.01 标准）<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>',
  vw_intro_list2: '在 Excel 程序中打开 CSV 文件。',
  vw_intro_list3: '在经销商进货价和非强制建议零售价列中输入您的特定市场价格。如果您不想指定价格或在您的市场不提供某一产品，你可以在价格栏简单地输入 “<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>on request<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>” 或 “<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>not available<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>”。',
  vw_intro_list4: '在大众、奥迪、SEAT、斯柯达、VWN 和保时捷等栏目中添加有关标准的信息。如果相关品牌的产品不是标准版，请在此输入 “<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>0<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>”，如果产品是标准的一部分，请输入 “<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>1<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>”。',
  vw_intro_list5: '请勿更改 “<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Product<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>” 和 “<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Article-number<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>” 列中的条目，以确保功能流畅。此外，不要删除或添加列或行，或更改列标题。',
  vw_intro_list6: '在您的电脑上将价目表本地另存为 CSV 文件。',
  vw_intro_list7: '使用下面的 “<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>选择文件<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>” 按钮或将 CSV 文件拖回灰色框再次上传您的价目表。',
  vw_intro_list8: '输入标题页面信息：您的市场、定价（经销商进货价或非强制建议零售价）和价格状态（价格信息的有效期）。',
  vw_intro_list9: '选择目录语言。',
  vw_intro_list10: '从 “<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>创建目录<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>” 按钮开始创建过程。',
  vw_legalNotice: '法律',
  vw_loginError: '密码不正确。',
  vw_loginMessage: '请登录以继续。',
  vw_market: '市场',
  vw_priceDate: '价格状态',
  vw_priceList: '价目表',
  vw_priceType: '定价',
  vw_priceTypeDNP: 'DNP',
  vw_priceTypeRRP: 'RRP',
  vw_stateConverting: '将创建您的目录。这可能需要一些时间。',
  vw_stateFailed: '发生错误。请重试。',
  vw_stateUploading: '您的数据将被上传。请稍等片刻，我们正在开始创建过程。',
  vw_stateWaiting: '您的目录已添加到等待队列中。',
  vw_title: 'Workshop Equipment Catalogue Creator',
  vw_uploaderMsgClickOrDragFile: '或将 CSV 文件拉至此处。',
  vw_uploaderMsgDropFile: '在此处保存文件。',
  vw_uploaderMsgFileCountError: '您只能上传一个文件。',
  vw_uploaderMsgFileTypeError: '您只能上传 CSV 文件。'

};
