import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { languages } from '@page2flip/i18n';
import { getTranslation } from '../locale/translation.provider';

/**
 * Component that represents the entry point of the application.
 */
@Component({
  selector: 'vw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  /** Available languages. */
  languages: any = languages;

  /** Display language. */
  language: string = getTranslation('__localeID') || 'en';

  /** Application title. */
  appTitle: string = getTranslation('vw_title') || 'Workshop Equipment Catalogue Creator';

  /** Current year. */
  year: number = new Date().getFullYear();

  /**
   * Constructor of the component.
   *
   * @param title A service that can be used to get and set the title of a current HTML document.
   */
  constructor(private title: Title) {
    title.setTitle(this.appTitle);
  }

  /**
   * Changes the application language.
   */
  public changeLanguage(): void {
    window.localStorage.setItem('language', this.language);
    window.location.reload();
  }

}
