import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { WINDOW } from '@page2flip/core';

/**
 * Component that represents the login form.
 */
@Component({
  selector: 'vw-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  /** Password. */
  @Input() password: string;

  /** User password. */
  userPassword: string;

  /** Emits an event when the user successfully authenticated. */
  @Output() authenticated = new EventEmitter<boolean>();

  /** Whether or not there was an error authenticating the user. */
  authenticationError = false;

  /**
   * Constructor of the component.
   *
   * @param window  DI Token for the Window object.
   */
  constructor(@Inject(WINDOW) private window: any) {
    requestAnimationFrame(() => {
      const password: string = this.window.sessionStorage.getItem('password');

      if (password === this.password) {
        this.userPassword = password;
        this.authenticate();
      }
    })
  }

  /**
   * Authenticates the user.
   */
  authenticate() {
    if (this.password === this.userPassword) {
      this.authenticated.emit(true);
      this.authenticationError = false;
      this.window.sessionStorage.setItem('password', this.userPassword);
    } else {
      this.authenticationError = true;
    }
  }

}
