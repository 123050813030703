import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Upload, UploadService } from '../upload/upload.service';
import { getTranslation } from '../../locale/translation.provider';
import { HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { ConverterState } from '@page2flip/core/common';
import { languages } from '@page2flip/i18n';
import { Observable } from 'rxjs';

@Component({
  selector: 'p2f-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css']
})
export class TemplatesComponent implements OnInit {

  constructor(
    public uploader: UploadService
  ) { }

  /** Whether or not the user is authenticated. */
  authenticated = false;

  /** Reference to the uploader container. */
  private uploaderRef: ElementRef;

  /** User CSV file. */
  private pdfFile: File;

  /** Available languages. */
  languages: any = languages;

  private isReady = false;

  private language = "en";

  private state: Upload = { state: 'PENDING', progress: 0 }

  private templateElements: TemplateElement[]

  displayedColumns: string[] = ['language', 'filename', 'lastmodified', 'actions'];

  ngOnInit(): void {
    this.fillTemplates();
  }

  @ViewChild('uploaderContainer')
  private set uploaderContainer(uploaderRef: ElementRef) {
    this.uploaderRef = uploaderRef;
  }

  handleFiles(event: Event | DragEvent | any) {
    this.pdfFile = event.target.files
      ? event.target.files[0]
      : event.dataTransfer.files[0];
    const parts: string[] = this.pdfFile.name.split('.');
    const type: string = parts[parts.length - 1].toLowerCase();

    if (type !== 'pdf') {
      this.isReady = false;
      return;
    }
    this.isReady = true;
  }

  submitData() {
    const data: any = {
      language: this.language,
    };

    this.uploader
      .replacePdf(data, this.pdfFile)
      .subscribe((upload) => {
        this.state = upload;
      },
        () => {
          this.pdfFile = undefined;
          this.fillTemplates();
        }
      );
  }

  fillTemplates() {
    this.uploader.fetchTemplates()
      .subscribe((templateResponse: HttpResponse<TemplateElement[]>) => {
        this.templateElements  = templateResponse.body;
      })
  }
}

export interface TemplateElement {
  language: string,
  fileName: string,
  lastModified: Date
}
