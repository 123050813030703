import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { WINDOW } from '@page2flip/core';
import { ConverterState } from '@page2flip/core/common';
import { languages } from '@page2flip/i18n';
import { getTranslation } from '../../locale/translation.provider';
import { UploadService } from './upload.service';
import { Router } from '@angular/router';

/**
 * Component that represents the upload view.
 */
@Component({
  selector: 'vw-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  /** Uploader messages. */
  private readonly messages: any = {
    clickOrDragFile:
      getTranslation('vw_uploaderMsgClickOrDragFile') ||
      'or drag a CSV file here.',
    dropFile: getTranslation('vw_uploaderMsgDropFile') || 'Drop the file here.',
    fileCountError:
      getTranslation('vw_uploaderMsgFileCountError') ||
      'You can only upload one file.',
    fileTypeError:
      getTranslation('vw_uploaderMsgFileTypeError') ||
      'You can only upload CSV files.'
  };

  /** Reference to the message container. */
  private messageRef: ElementRef;

  /** Reference to the uploader container. */
  private uploaderRef: ElementRef;

  /** User CSV file. */
  private csvFile: File;

  /** ZIP file name. */
  readonly filename = 'WK.zip'; // TODO: change

  /** Catalog preview URL. */
  previewUrl: SafeResourceUrl;

  /** Catalog download URL. */
  downloadUrl: SafeResourceUrl;

  /** Whether or not the user is authenticated. */
  authenticated = false;

  /** Whether or not to show the preview. */
  preview = false;

  /** Available languages. */
  languages: any = languages;

  /** Catalog language. */
  language: string = getTranslation('__localeID') || 'en';

  /** Catalog currency */
  currency: string;

  /** Location of the sub-medium. */
  location: string;

  /** Catalog market. */
  market: string;

  /** Catalog price date. */
  priceDate: Date;

  /** DNP price label. */
  priceTypeDNP: string = getTranslation('vw_priceTypeDNP') || 'DNP';

  /** RRP price label. */
  priceTypeRRP: string = getTranslation('vw_priceTypeRRP') || 'RRP';

  /** Catalog price type. */
  priceType = this.priceTypeDNP;

  /** Possible converter states. */
  state: ConverterState;

  /** Converter progress in %. */
  progress = 0;

  /** Whether or not the uploader is ready. */
  uploadReady = false;

  errorMessage: string;

  /**
   * Constructor of the component.
   *
   * @param window    DI Token for the Window object.
   * @param datePipe  Formats a date value according to locale rules.
   * @param renderer  Service that provides a low-level interface for modifying the UI.
   * @param router
   * @param sanitizer Sanitizes a value for use in the given SecurityContext.
   * @param uploader  Service to perform the CSV upload & publication generation.
   */
  constructor(
    @Inject(WINDOW) private window: any,
    private datePipe: DatePipe,
    private renderer: Renderer2,
    private router: Router,
    private sanitizer: DomSanitizer,
    public uploader: UploadService
  ) {}

  /**
   * Checks the status if there is a sub-medium URL in local storage.
   */
  ngOnInit() {
    this.location = this.window.localStorage.getItem('location');
    if (this.location) {
      this.state = this.window.localStorage.getItem('state');
      const mediaId = this.location.split('/')[5];
      const submediaId = this.location.split('/')[7];
      console.log('Redirecting to: ' + mediaId + ' | '+ submediaId);
      this.router.navigate(['/catalog', mediaId, submediaId]);
    }
  }

  /**
   * Sets the message container reference.
   *
   * @param messageRef  Reference to the message container.
   */
  @ViewChild('messageContainer')
  private set messageContainer(messageRef: ElementRef) {
    this.messageRef = messageRef;

    // set default message
    requestAnimationFrame(() => this.setMessage(this.messages.clickOrDragFile));
  }

  /**
   * Sets the uploader container reference.
   *
   * @param uploaderRef Reference to the uploader container.
   */
  @ViewChild('uploaderContainer')
  private set uploaderContainer(uploaderRef: ElementRef) {
    this.uploaderRef = uploaderRef;
  }

  /**
   * Sets uploader message.
   *
   * @param message Uploader message.
   */
  private setMessage(message: string) {
    if (this.messageRef) {
      this.messageRef.nativeElement.innerHTML = message;
    }
  }

  /**
   * Registers the events.
   */
  registerEvents() {
    requestAnimationFrame(() => {
      const uploader: HTMLDivElement = this.uploaderRef.nativeElement;

      this.renderer.listen(uploader, 'dragenter', (event: DragEvent) => {
        this.preventDefaults(event);
        this.setMessage(this.messages.dropFile);
        uploader.classList.add('dragging');
      });

      this.renderer.listen(uploader, 'dragleave', (event: DragEvent) => {
        this.preventDefaults(event);
        this.setMessage(this.messages.clickOrDragFile);
        uploader.classList.remove('dragging');
      });

      this.renderer.listen(uploader, 'dragover', (event: DragEvent) => {
        this.preventDefaults(event);
        this.setMessage(this.messages.dropFile);
        uploader.classList.add('dragging');
      });

      this.renderer.listen(uploader, 'drop', (event: DragEvent) => {
        this.preventDefaults(event);
        this.handleFiles(event);
        uploader.classList.remove('dragging');
      });
    });
  }

  /**
   * Prevents event defaults and propagation.
   *
   * @param event Drag event.
   */
  private preventDefaults(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * Handles dropped files.
   *
   * @param event (Drag) event.
   */
  handleFiles(event: Event | DragEvent | any) {
    // file dialog closed without a file selected
    if (event.target.files && !event.target.files.length) {
      this.setMessage(this.messages.clickOrDragFile);
      this.uploadReady = false;
      return;
    }

    // more than one file dragged
    if (event.dataTransfer && event.dataTransfer.files.length > 1) {
      this.setMessage(this.messages.fileCountError);
      this.uploadReady = false;
      return;
    }

    this.csvFile = event.target.files
      ? event.target.files[0]
      : event.dataTransfer.files[0];
    const parts: string[] = this.csvFile.name.split('.');
    const type: string = parts[parts.length - 1].toLowerCase();

    // wrong file type
    if (type !== 'csv') {
      this.setMessage(this.messages.fileTypeError);
      this.uploadReady = false;
      return;
    }

    this.setMessage(this.csvFile.name);
    this.uploadReady = true;
  }

  /**
   * Submits the user data & starts the converting process.
   */
  submitData() {
    const data: any = {
      language: this.language,
      market: this.market,
      currency: this.currency,
      priceDate: this.datePipe.transform(
        this.priceDate,
        getTranslation('_dateFormat') || 'dd/MM/yy'
      ),
      priceType: this.priceType
    };
    this.state = ConverterState.Uploading;

    this.uploader
      .generatePdf(data, this.csvFile)
      .subscribe((pdfResponse: HttpResponse<any>) => {
        if(pdfResponse.status === 201) {
          this.uploader
            .createMedium(pdfResponse.headers.get('Location'))
            .subscribe((mediumResponse: HttpResponse<any>) => {
              this.uploader
                .createSubMedium(mediumResponse.headers.get('Location'))
                .subscribe((subMediumResponse: HttpResponse<any>) => {
                  this.location = subMediumResponse.headers.get('Location');
                  this.window.localStorage.setItem('location', this.location);
                  //this.checkStatus(this.location);
                  const mediaId = this.location.split('/')[5];
                  const submediaId = this.location.split('/')[7];
                  console.log('Redirecting to: ' + mediaId + ' | '+ submediaId);
                  this.router.navigate(['/catalog', mediaId, submediaId]);
                });
            });
        }
      },
      (error: HttpErrorResponse) => {
        this.state = ConverterState.Failed;
        this.window.localStorage.setItem('state', this.state);
        this.errorMessage = error.error;
        console.log('--------------\n'+this.errorMessage);
        });
  }

  /**
   * Resets the uploader.
   */
  reset() {
    this.window.localStorage.removeItem('location');
    this.window.localStorage.removeItem('state');
    this.window.location.reload();
  }
}
