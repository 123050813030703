/** French translations. */
export const translations = {

  __localeID: 'fr',

  _dateFormat: 'dd/MM/yy',
  _shortDateFormat: 'dd/MM/yy, HH:mm',
  _mediumDateFormat: 'd MMMM yyyy, HH:mm',
  _longDateFormat: 'EEEE, d MMMM yyyy, HH:mm',

  _keyHome: 'Origine',
  _keyEnd: 'Fin',
  _keyShift: '⇧ Maj',
  _keyAlt: 'Alt',
  _keyControl: '✲ Ctrl',

  pageNotFound: 'Impossible de trouver la page que vous avez demandée.',

  accountApiAuthKey: 'Clé d\'autorisation API',
  accountAuthorities: 'Autorisations',
  accountEmail: 'Adresse e-mail',
  accountFirstName: 'Prénom',
  accountLanguage: 'Langue',
  accountLastName: 'Nom',
  accountPassword: 'Mot de passe',
  accountPasswordRetype: 'Répéter le mot de passe',
  accountScopes: 'Zones',
  accountTitle: 'Civilité',
  accountTitleMr: 'Monsieur',
  accountTitleMs: 'Madame',
  accountUsername: 'Nom d\'utilisateur',

  actions: 'Actions',
  actionCancel: 'Interrompre',
  actionClose: 'Fermer',
  actionCollapse: 'Réduire',
  actionCompare: 'Comparer',
  actionCreate: 'Créer',
  actionDelete: 'Supprimer',
  actionDownload: 'Télécharger',
  actionDownloadAsZip: 'Télécharger en tant que ZIP',
  actionEdit: 'Traiter',
  actionEditHotspots: 'Modifier les hotspots',
  actionExpand: 'Développer',
  actionLoad: 'Charger',
  actionLogIn: 'Se connecter',
  actionLogOut: 'Se déconnecter',
  actionMove: 'Déplacer',
  actionOpenInNewWindow: 'Ouvrir dans une nouvelle fenêtre',
  actionPageMarkerAdd: 'Ajouter un marqueur de page',
  actionPageMarkerRemove: 'Supprimer un marqueur de page',
  actionPrint: 'Imprimer',
  actionQuantityDecrease: 'Réduire le nombre',
  actionQuantityIncrease: 'Augmenter le nombre',
  actionRedo: 'Réessayer',
  actionRelease: 'Publier',
  actionRemove: 'Supprimer',
  actionReplace: 'Remplacer',
  actionReset: 'Réinitialiser',
  actionSave: 'Sauvegarder',
  actionSend: 'Envoyer',
  actionShowAll: 'Afficher tout',
  actionUndo: 'Annuler',
  actionUnrelease: 'Retirer une publication',
  actionUpload: 'Charger',
  actionView: 'Afficher',

  backgroundSizeCenter: 'Centrer',
  backgroundSizeFill: 'Remplir',
  backgroundSizeRepeat: 'Réessayer',
  backgroundSizeStretch: 'Étirer',

  bookmarks: 'Signet',
  bookmarksAdded: 'Page {{page}} ajoutée au signets',
  bookmarksNone: 'Vous n’avez pas encore de signets. Ouvrez l\'aperçu de la page et cliquez sur l\'icône <x id="START_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;mat-icon&gt;"/>bookmark<x id="CLOSE_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;/mat-icon&gt;"/> pour ajouter une page à vos signets.',
  bookmarksRemoved: 'Page {{page}} supprimée des signets',

  browserDimensions: 'ngpages n\'est pas compatible avec cette taille de fenêtre. Modifiez la taille de la fenêtre.',
  browserError1: 'Votre navigateur n\'est pas pris en charge par ngpages actuellement.',
  browserError2: 'Utilisez l\'un de nos navigateurs pris en charge.',

  config: 'Configuration',
  configAnimateCorners: 'Animer les coins',
  configAnimateHotspots: 'Animer les hotspots',
  configBackgroundImage: 'Fond d’écran',
  configBackgroundSize: 'Taille de l’arrière-plan',
  configCornerSize: 'Taille du coin (px)',
  configCoverPage: 'Page de couverture (applications uniquement)',
  configCurrency: 'Devise',
  configCustomCSS: 'Propre CSS',
  configCustomJS: 'Propre JavaScript',
  configDesign: 'Design',
  configDocumentMargin: 'Distance avec les documents (px)',
  configDoublePage: 'Pages doubles',
  configFeatures: 'Fonctionnalités',
  configHideToolbar: 'Masquer la barre d\'outils',
  configIndexing: 'Indexation par moteur de recherche',
  configLayout: 'Mise en page',
  configLoader: 'Précharger les images',
  configMenuPosition: 'Position du menu',
  configMiscellaneous: 'Divers',
  configPageFlipAnimation: 'Effet de feuille',
  configPageFlipSound: 'Bruit de feuille',
  configPageMarkerWidth: 'Largeur de marqueur de page (px)',
  configPrimaryBackgroundColor: 'Couleur de fond 1',
  configPrimaryForegroundColor: 'Couleur de texte 1',
  configSecondaryBackgroundColor: 'Couleur de fond 2',
  configSecondaryForegroundColor: 'Couleur de texte 2',
  configShop: 'Boutique',
  configShopArticleRegExp: 'Numéro d\'article Regex',
  configShopEndpoint: 'Endpoint',
  configSidePanelPosition: 'Position du panneau latéral',
  configToolbarPosition: 'Position de la barre d\'outils',
  configTracking: 'Suivi',
  configTransparency: 'Transparence',
  configVat: 'Taxe sur la valeur ajoutée (%)',
  configVatIncluded: 'TVA incluse',

  download: 'Télécharger',
  downloadAllPages: 'Toutes les pages',
  downloadPages: 'Pages',
  downloadPagesPlaceholder: 'p. ex. 1-5, 8, 11-13',
  downloadSelectedPages: 'Pages sélectionnées',

  editorInfoSelect: 'Dessinez un rectangle pour sélectionner les hotspots à l\'intérieur.',
  editorInfoDrawRect: 'Appuyez sur le bouton gauche de la souris pour commencer à dessiner un hotspot rectangulaire, puis relâchez-le pour terminer le hotspot.',
  editorInfoDrawCircle: 'Appuyez sur le bouton gauche de la souris pour commencer à dessiner un hotspot circulaire, puis relâchez-le pour terminer le hotspot.',
  editorInfoDrawEllipse: 'Appuyez sur le bouton gauche de la souris pour commencer à dessiner un hotspot elliptique, puis relâchez-le pour terminer le hotspot.',
  editorInfoDrawPolygon1: 'Appuyez sur le bouton gauche de la souris pour dessiner un hotspot polygonal ou pour ajouter un point au polygone.',
  editorInfoDrawPolygon2: 'Pour terminer le polygone, appuyez sur le bouton droit de la souris ou appuyez sur <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>⏎ Entrée<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>.',
  editorInfoDraw1: 'Pendant que vous dessinez un hotspot, appuyez sur <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>Esc<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> pour interrompre l’action.',
  editorInfoDraw2: 'Pendant que vous dessinez un hotspot, vous pouvez maintenir la touche <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>⇧ Maj<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> enfoncée pour l\'aligner sur la grille.',
  editorInfoDraw3: 'Cliquez sur un hotspot pour le sélectionner.<x id="LINE_BREAK" ctype="lb" equiv-text="&lt;br/&gt;"/>Maintenez la touche <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> enfoncée pour sélectionner plusieurs hotspots, et appuyez sur <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>A<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> pour sélectionner tous les hotspots.',
  editorInfoDraw4: 'Pour copier les hotspots sélectionnés, utilisez la combinaison <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>C<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> et <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>V<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> pour les coller.',
  editorInfoDraw5: 'Appuyez sur <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>Suppr<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> pour supprimer les hotspots sélectionnés.',
  editorInfoDelete: 'Cliquez sur un hotspot pour le supprimer.',

  editorGrid: 'Grille',
  editorGridColor: 'Couleur de la grille',
  editorGridSize: 'Taille de la grille (px)',
  editorGridVisibility: 'Afficher la grille',
  editorGridSnapToGrid: 'Aligner sur la grille',

  editorTools: 'Outils',
  editorToolDraw: 'Dessiner',
  editorToolSelect: 'Sélectionner',
  editorToolDelete: 'Supprimer',

  feedback: 'Commentaires',
  feedbackAdditionalInfo: 'Donnez-nous quelques informations supplémentaires (facultatif)',
  feedbackCategoryGeneral: 'Général',
  feedbackCategoryUi: 'Interface utilisateur',
  feedbackConfirmation: 'Merci de nous aider à améliorer ngpages !',
  feedbackEmailAddress: 'Votre adresse e-mail (facultatif)',
  feedbackError: 'Vos commentaires n\'ont pas pu être envoyés. Merci de réessayer ultérieurement.',
  feedbackSelectCategory: 'Sélectionnez une catégorie.',

  fullscreen: 'Plein écran',

  help: 'Aide',
  helpGestureMenu: 'Faites glisser vers le bas pour ouvrir le menu et faites glisser vers le haut pour le fermer.',
  helpGestureNav: 'Balayez vers la droite ou vers la gauche pour naviguer d’une page à l’autre.',
  helpGestureZoom: 'Touchez deux fois l\'écran pour agrandir la vue.',
  helpKeyboardShortcuts: 'Raccourcis clavier',
  helpNavigation: 'Navigation',
  helpPanelsAndDialogs: 'Panneaux et dialogues',
  helpZoom: 'Zoom',

  home: 'Commencer',
  homeIntro: 'Faites glisser un fichier PDF dans la fenêtre ou cliquez sur le bouton pour créer une nouvelle publication.',
  homeLetsRumble: 'Allons-y !',
  homeRequirements: 'Exigences pour votre PDF',
  homeWelcome: 'Bienvenue dans le tout nouveau ngpages !',

  hotspots: 'Hotspots',
  hotspotCount: '<x id="INTERPOLATION" equiv-text="{{editor.hotspots.length}}"/> Hotspots',
  hotspotCountSelected: '<x id="INTERPOLATION" equiv-text="{{editor.selectedHotspots.length}}"/> Hotspots sélectionnés',
  hotspotCountCopied: '<x id="INTERPOLATION" equiv-text="{{editor.copiedHotspots.length}}"/> Hotspots dans le presse-papiers',

  hotspotActionTypeArticle: 'Article',
  hotspotActionTypeAudio: 'Audio',
  hotspotActionTypeExternal: 'Lien externe',
  hotspotActionTypeFeature: 'Fonctionnalité',
  hotspotActionTypeGallery: 'Galerie',
  hotspotActionTypeIframe: 'iFrame',
  hotspotActionTypeImage: 'Image',
  hotspotActionTypeInfo: 'Info',
  hotspotActionTypeInternal: 'Lien interne',
  hotspotActionTypeMulti: 'Divers',
  hotspotActionTypeNone: 'Sélectionner',
  hotspotActionTypeVideo: 'Vidéo',

  hotspotDisplayTypeBorder: 'Cadre',
  hotspotDisplayTypeBox: 'Boîte',
  hotspotDisplayTypeHidden: 'Caché',
  hotspotDisplayTypeHover: 'Hover',
  hotspotDisplayTypeIcon: 'Icon',
  hotspotDisplayTypeInline: 'Inline',
  hotspotDisplayTypeMulti: 'Divers',
  hotspotDisplayTypeNone: 'Sélectionner',

  hotspotProperties: 'Propriétés du hotspot',
  hotspotPropertyType: 'Type',
  hotspotPropertyActionInternal: 'Numéro de page',
  hotspotPropertyActionExternal: 'URL',
  hotspotPropertyActionVideo: 'URL (MP4, WebM, Ogg, YouTube, Vimeo, Dailymotion)',
  hotspotPropertyActionAudio: 'URL (MP3, WAV, Ogg)',
  hotspotPropertyActionArticle: 'Numéro d’article',
  hotspotPropertyActionDefault: 'Action',
  hotspotPropertyTitle: 'Titre',
  hotspotPropertyDescription: 'Description',
  hotspotPropertyDisplayType: 'Type de présentation',
  hotspotPropertyColor: 'Couleur',
  hotspotPropertyAutoplay: 'Lecture automatique',
  hotspotPropertyPrice: 'Prix',
  hotspotPropertyPosition: 'Position',
  hotspotPropertyPositionX: 'X (px)',
  hotspotPropertyPositionY: 'Y (px)',
  hotspotPropertyDimensions: 'Dimensions',
  hotspotPropertyDimensionWidth: 'Largeur (px)',
  hotspotPropertyDimensionHeight: 'Hauteur (px)',

  hotspotShapes: 'Formes',
  hotspotShapeRect: 'Rectangle',
  hotspotShapeCircle: 'Cercle',
  hotspotShapeEllipse: 'Ellipse',
  hotspotShapePolygon: 'Polygone',

  info: 'Info',
  infoCopyright1: 'Copyright © 2019 wissenswerft GmbH',
  infoCopyright2: 'ngpages est un produit de wissenswerft GmbH.',
  infoTechnicalQuestions: 'Pour toute question technique, n\'hésitez pas à nous contacter à <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>support@page2flip.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',
  infoVisitUs: 'Visitez notre site Web sur <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>www.page2flip.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/> ou <x id="START_LINK_1" ctype="x-a" equiv-text="&lt;a&gt;"/>www.wissenswerft.net<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',

  legal: 'Mentions',
  legalImprint: 'légales',
  legalPrivacyPolicy: 'Politique de confidentialité',
  legalTermsOfService: 'Conditions d’utilisation',

  loading: 'Chargement en cours...',
  loadingPublication: 'Chargement de la publication...',
  loadingPreview: 'Chargement de l’aperçu...',

  loginInstance: 'Instance space.one',
  loginUsername: 'Nom d\'utilisateur',
  loginPassword: 'Mot de passe',
  loginStayLoggedIn: 'Rester connecté',

  menu: 'Menu',

  navigationFirstPage: 'Première page',
  navigationLastPage: 'Dernière page',
  navigationNextPage: 'Page suivante',
  navigationPage: 'Page',
  navigationPageBack: 'Retour à la page précédente',
  navigationPreviousPage: 'Page précédente',

  notes: 'Notes',
  notesNone: 'Vous n’avez pas encore de notes. Ouvrez l\'aperçu de la page et cliquez sur l\'icône <x id="START_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;mat-icon&gt;"/>comment<x id="CLOSE_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;/mat-icon&gt;"/> pour ajouter des notes à une page.',
  notesPlaceholder: 'Vos notes',
  notesRemoved: 'Notes supprimées pour la page {{page}}',
  notesSaved: 'Notes enregistrées pour la page {{pagr}}',
  notesTitle: 'Notes pour page <x id="INTERPOLATION" equiv-text="{{pageNumber}}"/>',

  offlineAvailability: 'Disponibilité hors ligne (applications uniquement)',

  pageComparison: 'Comparaison de page',
  pageComparisonError: 'Saisissez un numéro de page valide.',
  pageComparisonLeftPage: 'Page gauche',
  pageComparisonRightPage: 'Page droite',
  pageComparisonWarning: 'La comparaison de page n\'est pas disponible lorsque l\'effet de feuille est activé.',

  pageFlipAnimationHint: 'L\'effet de feuille n\'est disponible que dans la vue Bureau.',
  pageFlipAnimationWarning: 'L\'effet de feuille n\'est pas disponible lorsque la comparaison de page est activée.',

  pageOverview: 'Vue d’ensemble de la page',
  leftPage: 'Page gauche',
  rightPage: 'Page droite',

  pageMarkers: 'Marqueurs de page',
  pageMarkerColor: 'Couleur de marqueur',
  pageMarkerLabel: 'Désignation',
  pageMarkerLabelColor: 'Couleur du texte',
  pageMarkerNew: 'Nouveau marqueur de page',
  pageMarkerPage: 'Page',
  pageMarkerWarning: 'Les marqueurs de page ne sont pas disponibles lorsque l\'effet de feuille est activé.',

  paginatorItemsPerPageLabel: 'Entrées par page :',
  paginatorNextPageLabel: 'Page suivante',
  paginatorPreviousPageLabel: 'Page précédente',
  paginatorFirstPageLabel: 'Première page',
  paginatorLastPageLabel: 'Dernière page',
  paginatorRangeOfLabel: 'sur',

  positionBottom: 'en bas',
  positionLeft: 'à gauche',
  positionRight: 'à droite',
  positionTop: 'en haut',

  publication: 'Publication',
  publications: 'Publications',
  publicationsLatest: 'Vos publications les plus récentes',
  publicationAuthor: 'Auteur',
  publicationCover: 'Cover',
  publicationDateCreated: 'Créé',
  publicationDateModified: 'Traité',
  publicationDatePublished: 'Publié',
  publicationDelete: 'Supprimer la publication',
  publicationDeleteMessage: 'Êtes-vous sûr de vouloir supprimer cette publication ? Notez que vous ne pourrez plus annuler cette action.',
  publicationDetails: 'Détails',
  publicationDetailsEdit: 'Modifier les détails',
  publicationDescription: 'Description',
  publicationHotspots: 'Hotspots',
  publicationKeywords: 'Mots-clés',
  publicationLanguage: 'Langue',
  publicationPages: 'Pages',
  publicationTitle: 'Titre',

  releaseApp: 'Application',
  releaseDownload: 'Télécharger',
  releaseOnline: 'En ligne',
  releasePreview: 'Aperçu',

  search: 'Recherche',
  searchClear: 'Supprimer le terme de recherche',
  searchFoundMatches: '<x id="INTERPOLATION" equiv-text="{{searchResults.length}}"/> pages trouvées',
  searchNoMatches: 'Aucune page trouvée Saisissez au moins 3 caractères.',
  searchMatches: '<x id="INTERPOLATION" equiv-text="{{searchResult.matches}}"/> résultats',
  searchPlaceholder: 'Que recherchez-vous ?',

  settings: 'Paramètres',

  share: 'Partager',
  shareCurrentPage: 'Partager la page actuelle',
  shareSocialMedia: 'Médias sociaux',
  shareUrl: 'URL',

  shopAdditionalInfo: 'Informations supplémentaires ✎',
  shopItemNumber: 'Nº art.',
  shopVatExcluded: 'Hors {{vat}} % TVA',
  shopVatIncluded: '{{vat}} % TVA inclus',

  shoppingCart: 'Panier',
  shoppingCartAdd: 'Ajouter au panier',
  shoppingCartAdded: '{{title}} ajouté au panier',
  shoppingCartClear: 'Vider le panier',
  shoppingCartCleared: 'Panier vidé',
  shoppingCartEmpty: 'Vous n\'avez pas encore d\'articles dans votre panier.',
  shoppingCartPayNow: 'Payer maintenant',
  shoppingCartRemoved: '{{title}} retiré du panier',
  shoppingCartSum: 'Total',

  tableOfContents: 'Sommaire',
  tableOfContentsToggle: 'Réduire/Développer toutes les entrées',

  uploaderConfirm: 'Voulez-vous charger ce fichier ?',
  uploaderErrorFileCount: 'Vous ne pouvez charger qu\'un seul fichier PDF à la fois.',
  uploaderErrorFileType: 'Vous ne pouvez charger que des fichiers PDF.',
  uploaderFileDate: 'Date de modification : <x id="INTERPOLATION" equiv-text="{{file.lastModified | date:dateFormat}}"/>',
  uploaderFileDrop: 'Placez votre fichier PDF ici.',
  uploaderFileSize: 'Taille du fichier : <x id="INTERPOLATION" equiv-text="{{file.size / 1024 / 1024 | number}}"/> MB',
  uploaderUploading: 'Merci d’attendre pendant le téléchargement de votre fichier.',

  viewerPreview: 'Aperçu',
  viewerPreviewDesktop: 'Bureau',
  viewerPreviewPhone: 'Smartphone',
  viewerPreviewTablet: 'Tablette',
  viewerPreviewWarning: 'L\'aperçu peut différer de l\'apparence réelle.',

  watchList: 'Liste des envies',
  watchListAdd: 'Ajouter à la liste des envies',
  watchListAdded: '{{title}} ajouté à la liste des envies',
  watchListClear: 'Supprimer la liste des envies',
  watchListCleared: 'Liste des envies supprimée',
  watchListEmpty: 'Vous n\'avez pas encore ajouté d\'articles à votre liste des envies.',
  watchListExists: '{{title}} est déjà sur la liste des envies.',
  watchListRemoved: '{{title}} retiré de la liste des envies',

  wizardAnalysis: 'Évaluation',
  wizardAnalysisDescription: 'Statistiques et aperçus',
  wizardDocument: 'Document',
  wizardDocumentDescription: 'Synthèse et métadonnées',
  wizardHotspots: 'Hotspots',
  wizardHotspotsDescription: 'Créer et modifier',
  wizardPublication: 'Publication',
  wizardPublicationDescription: 'Publier et partager',
  wizardViewer: 'Viewer',
  wizardViewerDescription: 'Configuration et aperçu',

  zoom: 'Zoom',
  zoomError: 'Touchez deux fois l\'écran pour zoomer.',
  zoomIn: 'Zoom avant',
  zoomJumpBottom: 'Sauter vers le bas',
  zoomJumpLeft: 'Sauter vers la gauche',
  zoomJumpRight: 'Sauter vers la droite',
  zoomJumpTop: 'Sauter vers le haut',
  zoomMoveDown: 'Déplacer vers le bas',
  zoomMoveLeft: 'Déplacer vers la gauche',
  zoomMoveRight: 'Déplacer vers la droite',
  zoomMoveTop: 'Déplacer vers le haut',
  zoomOut: 'Zoom arrière',
  zoomReset: 'Réinitialiser le zoom',

  // vw-workshop-equipment specific translations

  vw_actionCreateCatalog: 'Créer le catalogue',
  vw_actionCreateNewCatalog: 'Créer un nouveau catalogue',
  vw_actionReset: 'Recommencer depuis le début',
  vw_actionDownloadCatalog: 'Télécharger le catalogue',
  vw_catalogLanguage: 'Langue du catalogue',
  vw_catalogPreview: 'Aperçu du catalogue',
  vw_catalogCurrency: 'Devise',
  vw_imprint: 'Mentions légales',
  vw_intro1: 'Bienvenue dans le Créateur du catalogue de vente Workshop Equipment de Volkswagen AG. Vous pourrez y créer le catalogue spécifique à votre secteur en quelques étapes très simples. Pour cela, nous vous recommandons d’ailleurs d\'utiliser les navigateurs Edge, Firefox ou Chrome.',
  vw_intro2: 'C\'est aussi simple que ça :',
  vw_intro3: 'Après environ 30 minutes, le processus de création est prêt et vous pouvez parcourir votre catalogue, le télécharger sous forme de dossier ZIP ou redémarrer le processus.',
  vw_intro4: 'Pour toute question sur le catalogue ou pour nous faire part de vos souhaits ou de vos suggestions, n’hésitez pas à nous contacter à <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>Workshop.Equipment@volkswagen.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',
  vw_intro_list1: 'Téléchargez le modèle de liste de prix sur votre PC. Il s\'agit d\'un fichier CSV contenant toute la gamme des produits inclus dans le catalogue, les spécifications PA et PVC conformes à la stratégie et aux normes européennes des marques du Groupe. Les produits avec facturation directe figurent dans la liste avec la mention "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>on request<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>".',
  vw_intro_list11: '<x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>Télécharger le modèle de liste de prix (version : prix 01.01.2021, normes 01.01.2021)<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>',
  vw_intro_list2: 'Ouvrez le fichier CSV dans le programme Excel.',
  vw_intro_list3: 'Saisissez les prix spécifiques à votre secteur dans les colonnes PA et PVC. Si vous ne souhaitez pas spécifier de prix ou si vous ne proposez pas un produit donné sur votre marché, vous pouvez simplement saisir "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>on request<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>" ou "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>not available<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>" dans la zone du prix.',
  vw_intro_list4: 'Ajoutez vos informations sur la norme dans les colonnes VW, Audi, SEAT, SKODA, VWN et Porsche. Saisissez-y un "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>0<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>" si le produit n\'est pas standard pour la marque concernée, ou entrez un "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>1<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>" si elle fait partie de la norme.',
  vw_intro_list5: 'Ne modifiez pas les entrées des colonnes "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Product<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>" et "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Article-number<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>" pour assurer une parfaite fonctionnalité. En outre, ne supprimez ou n\'ajoutez ni colonnes ni lignes, et ne modifiez pas les titres des colonnes.',
  vw_intro_list6: 'Enregistrez votre liste de prix en tant que fichier CSV en local sur votre PC.',
  vw_intro_list7: 'Téléchargez votre liste de prix à l\'aide du bouton "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Sélectionner le fichier<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>" ou en faisant glisser le fichier CSV vers la zone grise.',
  vw_intro_list8: 'Saisissez les informations de la page de titre : votre marché, votre prix (PA ou PVC) et le tarif actuel (date de validité des informations sur le prix).',
  vw_intro_list9: 'Sélectionnez la langue du catalogue.',
  vw_intro_list10: 'Démarrez le processus de création à partir du bouton "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Créer le catalogue<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>".',
  vw_legalNotice: 'Mentions légales',
  vw_loginError: 'Mot de passe incorrect.',
  vw_loginMessage: 'Veuillez vous connecter pour continuer.',
  vw_market: 'Marché',
  vw_priceDate: 'Tarif en vigueur',
  vw_priceList: 'Liste de prix',
  vw_priceType: 'Fixation des prix',
  vw_priceTypeDNP: 'DNP',
  vw_priceTypeRRP: 'RRP',
  vw_stateConverting: 'Votre catalogue est en cours de création. Cette opération peut prendre un certain temps.',
  vw_stateFailed: 'Une erreur est survenue. Refaites une tentative.',
  vw_stateUploading: 'Vos données sont en cours de téléchargement. Patientez pendant que nous lançons le processus de création.',
  vw_stateWaiting: 'Votre catalogue a été ajouté à la file d\'attente.',
  vw_title: 'Workshop Equipment Catalogue Creator',
  vw_uploaderMsgClickOrDragFile: 'ou faites glisser le fichier CSV ici.',
  vw_uploaderMsgDropFile: 'Déposer le fichier ici.',
  vw_uploaderMsgFileCountError: 'Vous ne pouvez charger qu\'un seul fichier.',
  vw_uploaderMsgFileTypeError: 'Vous ne pouvez charger que des fichiers CSV.'

};
