/** Swedish translations. */
export const translations = {

  __localeID: 'sv',

  _dateFormat: 'yy-MM-dd',
  _shortDateFormat: 'yy-MM-dd, HH.mm',
  _mediumDateFormat: 'd MMMM yyyy, HH.mm',
  _longDateFormat: 'EEEE \'den\' d MMMM yyyy, HH.mm',

  _keyHome: 'Home',
  _keyEnd: 'End',
  _keyShift: '⇧ Skift',
  _keyAlt: 'Alt',
  _keyControl: '✲ Ctrl',

  pageNotFound: 'Sidan du begärde kunde inte hittas.',

  accountApiAuthKey: 'API-auktoriseringnyckel',
  accountAuthorities: 'Behörigheter',
  accountEmail: 'E-postadress',
  accountFirstName: 'Namn',
  accountLanguage: 'Språk',
  accountLastName: 'Efternamn',
  accountPassword: 'Lösenord',
  accountPasswordRetype: 'Upprepa lösenord',
  accountScopes: 'Områden',
  accountTitle: 'Titel',
  accountTitleMr: 'Herr',
  accountTitleMs: 'Fru',
  accountUsername: 'Användarnamn',

  actions: 'Åtgärder',
  actionCancel: 'Avbryt',
  actionClose: 'Stäng',
  actionCollapse: 'Minimera',
  actionCompare: 'Jämför',
  actionCreate: 'Skapa',
  actionDelete: 'Radera',
  actionDownload: 'Ladda ner',
  actionDownloadAsZip: 'Ladda ner som ZIP',
  actionEdit: 'Redigera',
  actionEditHotspots: 'Redigera hotspots',
  actionExpand: 'Expandera',
  actionLoad: 'Ladda',
  actionLogIn: 'Logga in',
  actionLogOut: 'Logga ut',
  actionMove: 'Flytta',
  actionOpenInNewWindow: 'Öppna i nytt fönster',
  actionPageMarkerAdd: 'Lägga till sidmarkörer',
  actionPageMarkerRemove: 'Ta bort sidmarkörer',
  actionPrint: 'Skriv ut',
  actionQuantityDecrease: 'Minska antalet',
  actionQuantityIncrease: 'Öka antalet',
  actionRedo: 'Upprepa',
  actionRelease: 'Publicera',
  actionRemove: 'Ta bort',
  actionReplace: 'Ersätt',
  actionReset: 'Återställ',
  actionSave: 'Spara',
  actionSend: 'Skicka',
  actionShowAll: 'Visa alla',
  actionUndo: 'Ångra',
  actionUnrelease: 'Opublicerade',
  actionUpload: 'Ladda upp',
  actionView: 'Visa',

  backgroundSizeCenter: 'Centrera',
  backgroundSizeFill: 'Fyll i',
  backgroundSizeRepeat: 'Upprepa',
  backgroundSizeStretch: 'Sträck ut',

  bookmarks: 'Bokmärke',
  bookmarksAdded: 'Tillagd sida {{page}} till bokmärken.',
  bookmarksNone: 'Du har inte några bokmärken ännu. Öppna sidöversikten och klicka på ikonen <x id="START_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;mat-icon&gt;"/>bookmark<x id="CLOSE_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;/mat-icon&gt;"/> för att lägga till en sida till dina bokmärken.',
  bookmarksRemoved: 'Borttagen sida {{page}} med bokmärken.',

  browserDimensions: 'ngpages är inte kompatibel med den här fönsterstorleken. Ändra fönstrets storlek.',
  browserError1: 'Din webbläsare stöds för närvarande inte av ngpages.',
  browserError2: 'Använd en av våra webbläsare som stöds.',

  config: 'Konfiguration',
  configAnimateCorners: 'Animera hörn',
  configAnimateHotspots: 'Animera hotspots',
  configBackgroundImage: 'Bakgrundsbild',
  configBackgroundSize: 'Bakgrundsstorlek',
  configCornerSize: 'Hörnstorlek (px)',
  configCoverPage: 'Försättsblad (endast appar)',
  configCurrency: 'Valuta',
  configCustomCSS: 'Egen CSS',
  configCustomJS: 'Egen JavaScript',
  configDesign: 'Design',
  configDocumentMargin: 'Dokumentavstånd (px)',
  configDoublePage: 'Dubbelsidor',
  configFeatures: 'Funktioner',
  configHideToolbar: 'Dölj verktygsfält',
  configIndexing: 'Sökmotorindexering',
  configLayout: 'Layout',
  configLoader: 'Förladda bilder',
  configMenuPosition: 'Menyposition',
  configMiscellaneous: 'Övrigt',
  configPageFlipAnimation: 'Bläddereffekt',
  configPageFlipSound: 'Blädderljud',
  configPageMarkerWidth: 'Bredd på sidmarkör (px)',
  configPrimaryBackgroundColor: 'Bakgrundsfärg 1',
  configPrimaryForegroundColor: 'Textfärg 1',
  configSecondaryBackgroundColor: 'Bakgrundsfärg 2',
  configSecondaryForegroundColor: 'Textfärg 2',
  configShop: 'Butik',
  configShopArticleRegExp: 'Artikelnummer Regex',
  configShopEndpoint: 'Slutpunkt',
  configSidePanelPosition: 'Sidopanelens placering',
  configToolbarPosition: 'Verktygsfälts placering',
  configTracking: 'Spårning',
  configTransparency: 'Öppenhet',
  configVat: 'Omsättningsskatt (%)',
  configVatIncluded: 'inklusive moms',

  download: 'Ladda ner',
  downloadAllPages: 'Alla sidor',
  downloadPages: 'Sidor',
  downloadPagesPlaceholder: 't.ex. 1-5, 8, 11-13',
  downloadSelectedPages: 'Valda sidor',

  editorInfoSelect: 'Rita en rektangel för att välja hotspots inom.',
  editorInfoDrawRect: 'Tryck på vänster musknapp för att börja rita en rektangulär hotspot och släpp den för att avsluta din hotspot.',
  editorInfoDrawCircle: 'Tryck på vänster musknapp för att börja rita en cirkelformad hotspot och släpp den för att slutföra din hotspot.',
  editorInfoDrawEllipse: 'Tryck på vänster musknapp för att börja rita en elliptisk hotspot och släpp den för att slutföra din hotspot.',
  editorInfoDrawPolygon1: 'Tryck på vänster musknapp för att rita en ny polygon hotspot eller för att lägga till en punkt till polygonen.',
  editorInfoDrawPolygon2: 'Tryck på höger musknapp eller <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>⏎ Retur<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> för att slutföra polygonen.',
  editorInfoDraw1: 'När du ritar en hotspot trycker du på <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>Esc<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> för att avbryta.',
  editorInfoDraw2: 'När du ritar en hotspot kan du hålla ned <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>⇧ Skift<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> för att justera den mot rutnätet.',
  editorInfoDraw3: 'Klicka på en hotspot för att markera den.<x id="LINE_BREAK" ctype="lb" equiv-text="&lt;br/&gt;"/>Du kan markera flera hotspots medan du håller ned <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> och du kan markera alla aktiveringspunkter genom att trycka på <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>A<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>.',
  editorInfoDraw4: 'Du kan kopiera markerade hotspots med <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>C<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> och klistra in dem med <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>V<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>.',
  editorInfoDraw5: 'Tryck på <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>Del<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> för att ta bort markerade hotspots.',
  editorInfoDelete: 'Klicka på en hotspot för att ta bort den.',

  editorGrid: 'Rutnät',
  editorGridColor: 'Rutnätsfärg',
  editorGridSize: 'Rutnätsstorlek (px)',
  editorGridVisibility: 'Visa rutnät',
  editorGridSnapToGrid: 'Justera mot rutnätet',

  editorTools: 'Verktyg',
  editorToolDraw: 'Rita',
  editorToolSelect: 'Välj',
  editorToolDelete: 'Radera',

  feedback: 'Feedback',
  feedbackAdditionalInfo: 'Berätta lite mer (valfritt)',
  feedbackCategoryGeneral: 'Allmänt',
  feedbackCategoryUi: 'Användargränssnitt',
  feedbackConfirmation: 'Tack för att du hjälper oss att förbättra ngpages!',
  feedbackEmailAddress: 'Din e-postadress (valfritt)',
  feedbackError: 'Det gick inte att skicka din feedback. Vänligen försök igen senare.',
  feedbackSelectCategory: 'Välj en kategori',

  fullscreen: 'Helskärm',

  help: 'Hjälp',
  helpGestureMenu: 'Svep nedåt för att öppna menyn och svep uppåt för att stänga den.',
  helpGestureNav: 'Svep åt höger eller vänster för att navigera mellan sidorna.',
  helpGestureZoom: 'Dubbeltryck på skärmen för att förstora vyn.',
  helpKeyboardShortcuts: 'Kortkommando',
  helpNavigation: 'Navigering',
  helpPanelsAndDialogs: 'Paneler och dialoger',
  helpZoom: 'Zooma',

  home: 'Starta',
  homeIntro: 'Dra en PDF-fil till fönstret eller klicka på knappen för att skapa en ny publikation.',
  homeLetsRumble: 'Nu börjar vi!',
  homeRequirements: 'Krav på din PDF',
  homeWelcome: 'Välkommen till den helt nya ngpages!',

  hotspots: 'Hotspots',
  hotspotCount: '<x id="INTERPOLATION" equiv-text="{{editor.hotspots.length}}"/> Hotspots',
  hotspotCountSelected: '<x id="INTERPOLATION" equiv-text="{{editor.selectedHotspots.length}}"/> Markerade hotspots',
  hotspotCountCopied: '<x id="INTERPOLATION" equiv-text="{{editor.copiedHotspots.length}}"/> Hotspots i Urklipp',

  hotspotActionTypeArticle: 'Artikel',
  hotspotActionTypeAudio: 'Ljud',
  hotspotActionTypeExternal: 'Extern länk',
  hotspotActionTypeFeature: 'Funktion',
  hotspotActionTypeGallery: 'Galleri',
  hotspotActionTypeIframe: 'iFrame',
  hotspotActionTypeImage: 'Bild',
  hotspotActionTypeInfo: 'Info',
  hotspotActionTypeInternal: 'Intern länk',
  hotspotActionTypeMulti: 'diverse',
  hotspotActionTypeNone: 'Vänligen välj',
  hotspotActionTypeVideo: 'Video',

  hotspotDisplayTypeBorder: 'Rama in',
  hotspotDisplayTypeBox: 'Ruta',
  hotspotDisplayTypeHidden: 'Dold',
  hotspotDisplayTypeHover: 'Håll över',
  hotspotDisplayTypeIcon: 'Ikon',
  hotspotDisplayTypeInline: 'Inline',
  hotspotDisplayTypeMulti: 'diverse',
  hotspotDisplayTypeNone: 'Vänligen välj',

  hotspotProperties: 'Hotspot-egenskaper',
  hotspotPropertyType: 'Typ',
  hotspotPropertyActionInternal: 'Sidnummer',
  hotspotPropertyActionExternal: 'URL',
  hotspotPropertyActionVideo: 'URL (MP4, WebM, OGG, YouTube, Vimeo, Dailymotion)',
  hotspotPropertyActionAudio: 'URL (MP3, WAV, Ogg)',
  hotspotPropertyActionArticle: 'Artikelnummer',
  hotspotPropertyActionDefault: 'Åtgärd',
  hotspotPropertyTitle: 'Titel',
  hotspotPropertyDescription: 'Beskrivning',
  hotspotPropertyDisplayType: 'Presentationstyp',
  hotspotPropertyColor: 'Färg',
  hotspotPropertyAutoplay: 'automatisk uppspelning',
  hotspotPropertyPrice: 'Pris',
  hotspotPropertyPosition: 'Position',
  hotspotPropertyPositionX: 'X (px)',
  hotspotPropertyPositionY: 'Y (px)',
  hotspotPropertyDimensions: 'Dimensioner',
  hotspotPropertyDimensionWidth: 'Bredd (px)',
  hotspotPropertyDimensionHeight: 'Höjd (px)',

  hotspotShapes: 'Former',
  hotspotShapeRect: 'Rektangel',
  hotspotShapeCircle: 'Cirkel',
  hotspotShapeEllipse: 'Ellips',
  hotspotShapePolygon: 'Polygon',

  info: 'Info',
  infoCopyright1: 'Copyright © 2019 wissenswerft GmbH',
  infoCopyright2: 'ngpages är en produkt från wissenswerft GmbH.',
  infoTechnicalQuestions: 'Tveka inte att kontakta oss på <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>support@page2flip.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/> om du har frågor.',
  infoVisitUs: 'Besök oss på webben på <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>www.page2flip.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/> eller <x id="START_LINK_1" ctype="x-a" equiv-text="&lt;a&gt;"/>www.wissenswerft.net<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',

  legal: 'Rättsligt',
  legalImprint: 'Impressum',
  legalPrivacyPolicy: 'Sekretesspolicy',
  legalTermsOfService: 'Villkor',

  loading: 'Laddar ...',
  loadingPublication: 'Ladda publikation ...',
  loadingPreview: 'Ladda förhandsgransknin ...',

  loginInstance: 'space.one-instans',
  loginUsername: 'Användarnamn',
  loginPassword: 'Lösenord',
  loginStayLoggedIn: 'Håll dig inloggad',

  menu: 'Meny',

  navigationFirstPage: 'Första sidan',
  navigationLastPage: 'Sista sidan',
  navigationNextPage: 'Nästa sida',
  navigationPage: 'Sida',
  navigationPageBack: 'Sida tillbaka',
  navigationPreviousPage: 'Föregående sida',

  notes: 'Anteckningar',
  notesNone: 'Du har inga anteckningar än. Öppna sidöversikten och klicka på ikonen <x id="START_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;mat-icon&gt;"/>comment<x id="CLOSE_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;/mat-icon&gt;"/> för att lägga till anteckningar på en sida.',
  notesPlaceholder: 'Dina anteckningar',
  notesRemoved: 'Borttagna anteckningar för sidan {{page}}.',
  notesSaved: 'Anteckningar som sparats för sidan {{page}}.',
  notesTitle: 'Anteckningar för sidan <x id="INTERPOLATION" equiv-text="{{pageNumber}}"/>',

  offlineAvailability: 'Offlinetillgänglighet (endast appar)',

  pageComparison: 'Sidojämförelse',
  pageComparisonError: 'Ange ett giltigt sidnummer.',
  pageComparisonLeftPage: 'Vänster sida',
  pageComparisonRightPage: 'Höger sida',
  pageComparisonWarning: 'Sidojämförelsen är inte tillgänglig när bläddereffekten är aktiverad.',

  pageFlipAnimationHint: 'Bläddereffekten är endast tillgänglig i skrivbordsvyn.',
  pageFlipAnimationWarning: 'Bläddereffekten är inte tillgänglig när sidojämförelsen är aktiverad.',

  pageOverview: 'Webbplatskarta',
  leftPage: 'Vänster sida',
  rightPage: 'Höger sida',

  pageMarkers: 'Sidomarkörer',
  pageMarkerColor: 'Markeringsfärg',
  pageMarkerLabel: 'Beteckning',
  pageMarkerLabelColor: 'Textfärg',
  pageMarkerNew: 'Ny sidomarkör',
  pageMarkerPage: 'Sida',
  pageMarkerWarning: 'Sidomarkörer är inte tillgängliga när bläddereffekten är aktiverad.',

  paginatorItemsPerPageLabel: 'Poster per sida:',
  paginatorNextPageLabel: 'Nästa sida',
  paginatorPreviousPageLabel: 'Föregående sida',
  paginatorFirstPageLabel: 'Första sidan',
  paginatorLastPageLabel: 'Sista sidan',
  paginatorRangeOfLabel: 'från',

  positionBottom: 'Nere',
  positionLeft: 'Vänster',
  positionRight: 'Höger',
  positionTop: 'Uppe',

  publication: 'Publikation',
  publications: 'Publikationer',
  publicationsLatest: 'Dina senaste publikationer',
  publicationAuthor: 'Författare',
  publicationCover: 'Täck',
  publicationDateCreated: 'Skapad',
  publicationDateModified: 'Redigerad',
  publicationDatePublished: 'Publicerad',
  publicationDelete: 'Ta bort publikation',
  publicationDeleteMessage: 'Är du säker på att du vill ta bort publikationen? Observera att detta inte går att ångra.',
  publicationDetails: 'Detaljer',
  publicationDetailsEdit: 'Redigera detaljer',
  publicationDescription: 'Beskrivning',
  publicationHotspots: 'Hotspots',
  publicationKeywords: 'Sökord',
  publicationLanguage: 'Språk',
  publicationPages: 'Sidor',
  publicationTitle: 'Titel',

  releaseApp: 'App',
  releaseDownload: 'Hämta',
  releaseOnline: 'Online',
  releasePreview: 'Förhandsgranska',

  search: 'Sök',
  searchClear: 'Ta bort söktermen',
  searchFoundMatches: '<x id="INTERPOLATION" equiv-text="{{searchResults.length}}"/> sidor hittades.',
  searchNoMatches: 'Inga sidor hittades. Ange minst 3 tecken.',
  searchMatches: '<x id="INTERPOLATION" equiv-text="{{searchResult.matches}}"/> resultat',
  searchPlaceholder: 'Vad letar du efter?',

  settings: 'Inställningar',

  share: 'Dela',
  shareCurrentPage: 'Dela den aktuella sidan',
  shareSocialMedia: 'Sociala medier',
  shareUrl: 'URL',

  shopAdditionalInfo: 'Ytterligare information ✎',
  shopItemNumber: 'Artikelnr',
  shopVatExcluded: 'exkl. {{vat}} % moms',
  shopVatIncluded: 'inkl. {{vat}} % moms',

  shoppingCart: 'Kundvagn',
  shoppingCartAdd: 'Lägg i kundvagn',
  shoppingCartAdded: '{{title}} läggs till i kundvagnen.',
  shoppingCartClear: 'Töm kundvagnen',
  shoppingCartCleared: 'Kundvagnen tömd.',
  shoppingCartEmpty: 'Du har ännu inga artiklar i din kundvagn.',
  shoppingCartPayNow: 'Betala nu',
  shoppingCartRemoved: '{{title}} tas bort från kundvagnen.',
  shoppingCartSum: 'Summa',

  tableOfContents: 'Innehållsförteckning',
  tableOfContentsToggle: 'Fäll ut/in alla poster',

  uploaderConfirm: 'Vill du ladda upp den här filen?',
  uploaderErrorFileCount: 'Du kan bara ladda upp en PDF-fil åt gången.',
  uploaderErrorFileType: 'Du kan bara ladda upp PDF-filer.',
  uploaderFileDate: 'Ändringsdatum: <x id="INTERPOLATION" equiv-text="{{file.lastModified | date:dateFormat}}"/>',
  uploaderFileDrop: 'Placera din PDF-fil här.',
  uploaderFileSize: 'Filstorlek: <x id="INTERPOLATION" equiv-text="{{file.size / 1024 / 1024 | number}}"/> MB',
  uploaderUploading: 'Vänligen vänta medan vi laddar upp din fil.',

  viewerPreview: 'Förhandsgranska',
  viewerPreviewDesktop: 'Skrivbordet',
  viewerPreviewPhone: 'Smartphone',
  viewerPreviewTablet: 'Läsplatta',
  viewerPreviewWarning: 'Förhandsgranskningen kan skilja sig från det faktiska utseendet.',

  watchList: 'Bevakningslista',
  watchListAdd: 'Lägg till i bevakningslista',
  watchListAdded: '{{title}} tillagt till bevakningslistan.',
  watchListClear: 'Ta bort bevakningslista',
  watchListCleared: 'Bevakningslista borttagen.',
  watchListEmpty: 'Du har ännu inga artiklar på bevakningslistan.',
  watchListExists: '{{title}} finns redan på bevakningslistan.',
  watchListRemoved: '{{title}} tas bort från bevakningslistan.',

  wizardAnalysis: 'Utvärdering',
  wizardAnalysisDescription: 'Statistik och insyn',
  wizardDocument: 'Dokument',
  wizardDocumentDescription: 'Översikt och metadata',
  wizardHotspots: 'Hotspots',
  wizardHotspotsDescription: 'Skapa och redigera',
  wizardPublication: 'Publikation',
  wizardPublicationDescription: 'Publicera och dela',
  wizardViewer: 'Viewer',
  wizardViewerDescription: 'Konfiguration och förhandsgranskning',

  zoom: 'Zooma',
  zoomError: 'Dubbeltryck på skärmen för att zooma.',
  zoomIn: 'Förstora zoom',
  zoomJumpBottom: 'Hoppar ner',
  zoomJumpLeft: 'Hoppa åt vänster',
  zoomJumpRight: 'Hoppa åt höger',
  zoomJumpTop: 'Hoppar upp',
  zoomMoveDown: 'Flytta ned',
  zoomMoveLeft: 'Flytta åt vänster',
  zoomMoveRight: 'Flytta åt höger',
  zoomMoveTop: 'Flytta upp',
  zoomOut: 'Förminska zoom',
  zoomReset: 'Återställ zoom',

  // vw-workshop-equipment specific translations

  vw_actionCreateCatalog: 'Skapa katalog',
  vw_actionCreateNewCatalog: 'Skapa en ny katalog',
  vw_actionReset: 'Börja från början',
  vw_actionDownloadCatalog: 'Ladda ner katalogen',
  vw_catalogLanguage: 'Katalogspråk',
  vw_catalogPreview: 'Förhandsgranska katalog',
  vw_catalogCurrency: 'Valuta',
  vw_imprint: 'Impressum',
  vw_intro1: 'Välkommen till skaparen av försäljningskatalogen för verkstadsutrustning från Volkswagen AG. Här kan du enkelt skapa din marknadsspecifika katalog med bara några få steg. Vi rekommenderar för övrigt att du använder webbläsaren Edge, Firefox eller Chrome för detta ändamål.',
  vw_intro2: 'Så här enkelt är det:',
  vw_intro3: 'Efter ca 30 minuter är utvecklingsprocessen klar och du kan bläddra i din katalog, ladda ner den som ett ZIP-paket eller starta om processen.',
  vw_intro4: 'Om du har några frågor om katalogen, önskemål eller idéer för förbättring, ser vi fram emot att få din feedback på <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>Workshop.Equipment@volkswagen.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',
  vw_intro_list1: 'Ladda ned prislistmallen till din dator. Detta är en CSV-fil med hela sortimentet av produkter som ingår i katalogen, de strategikompatibla HEP- och UPE-specifikationerna och EU-standarder för gruppen varumärken. Produkter med direkt fakturering finns i listan med indikationen "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>on request<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>".',
  vw_intro_list11: '<x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>Ladda ner prislistmall (status: priser 01.01.2021, standarder 01.01.2021)<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>',
  vw_intro_list2: 'Öppna CSV-filen i Excel-programmet.',
  vw_intro_list3: 'Ange dina marknadsspecifika priser i kolumnerna Inköpspris och Rekommenderat pris. Om du inte vill ange ett pris eller inte erbjuder en produkt på din marknad, kan du helt enkelt ange ”<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>on request<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>” eller ”<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>not available<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>” i prisfältet.',
  vw_intro_list4: 'Lägg till din information om standarden i kolumnerna VW, Audi, SEAT, SKODA, VWN och Porsche. Ange en "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>0<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>" om produkten inte är standard med det relevanta varumärket eller ange en "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>1<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>" om det är en del av standarden.',
  vw_intro_list5: 'Ändra inte poster i kolumnerna ”<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Product<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>” och ”<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Article-number<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>” för att säkerställa smidig funktionalitet. Ta heller inte bort eller lägg till kolumner, rader eller ändra kolumnrubrikerna.',
  vw_intro_list6: 'Spara din prislista som en CSV-fil lokalt på din dator.',
  vw_intro_list7: 'Ladda ner din prislista med knappen "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Välj fil<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>" nedan eller genom att dra CSV-filen tillbaka till den grå rutan.',
  vw_intro_list8: 'Ange information om titelsidan: din marknad, din prissättning (inköpspris eller rekommenderat pris) och prisnivån (giltighetsdatum för prisinformationen).',
  vw_intro_list9: 'Välj katalogspråk.',
  vw_intro_list10: 'Starta skapandeprocessen från knappen "<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Skapa katalog<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>".',
  vw_legalNotice: 'Rättsligt',
  vw_loginError: 'Felaktigt lösenord.',
  vw_loginMessage: 'Vänligen logga in för att fortsätta.',
  vw_market: 'Marknad',
  vw_priceDate: 'Aktuella priser',
  vw_priceList: 'Prislista',
  vw_priceType: 'Prissättning',
  vw_priceTypeDNP: 'DNP',
  vw_priceTypeRRP: 'RRP',
  vw_stateConverting: 'Din katalog kommer att skapas. Detta kan ta lite tid.',
  vw_stateFailed: 'Ett fel har uppstått. Försök igen.',
  vw_stateUploading: 'Dina data kommer att överföras. Vänta en stund medan vi börjar skapandeprocessen.',
  vw_stateWaiting: 'Din katalog har lagts till i kön.',
  vw_title: 'Workshop Equipment Catalogue Creator',
  vw_uploaderMsgClickOrDragFile: 'eller dra hit CSV-filen.',
  vw_uploaderMsgDropFile: 'Placera filen här.',
  vw_uploaderMsgFileCountError: 'Du kan bara ladda upp en fil.',
  vw_uploaderMsgFileTypeError: 'Du kan bara ladda upp CSV-filer.'

};
