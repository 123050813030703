import { DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NxModule } from '@nrwl/nx';

import { WINDOW, windowFactory } from '@page2flip/core';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { AdminComponent } from './admin/admin.component';
import { LoaderComponent } from './loader/loader.component';
import { LoginComponent } from './login/login.component';
import { MaterialModule } from './material/material.module';
import { UploadComponent } from './upload/upload.component';
import { UploadService } from './upload/upload.service';
import { TemplatesComponent } from './templates/templates.component';
import { MatProgressBarModule, MatProgressSpinnerModule, MatTableModule } from '@angular/material';
import { CatalogComponent } from './catalog/catalog.component';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    AdminComponent,
    LoaderComponent,
    LoginComponent,
    UploadComponent,
    TemplatesComponent,
    CatalogComponent
  ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    NxModule.forRoot(),
    RouterModule.forRoot(routes),
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTableModule
  ],
  providers: [
    DatePipe,
    UploadService,
    { provide: WINDOW, useFactory: windowFactory }
  ]
})
export class AppModule {}
