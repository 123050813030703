import { Routes } from '@angular/router';

import { AdminComponent } from './admin/admin.component';
import { UploadComponent } from './upload/upload.component';
import { TemplatesComponent } from './templates/templates.component';
import { CatalogComponent } from './catalog/catalog.component';

export const routes: Routes = [
  {
    path: 'upload',
    component: UploadComponent
  },
  {
    path: 'templates',
    component: TemplatesComponent
  },
  {
    path: 'catalog/:mediaId/:submediaId',
    component: CatalogComponent
  },
  {
    path: 'admin',
    component: AdminComponent
  },
  {
    path: '',
    redirectTo: '/upload',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: UploadComponent
  }
];
