/** Spanish translations. */
export const translations = {

  __localeID: 'es',

  _dateFormat: 'dd/MM/yy',
  _shortDateFormat: 'dd/MM/yy, HH:mm',
  _mediumDateFormat: 'd \'de\' MMMM \'de\' yyyy, HH:mm',
  _longDateFormat: 'd \'de\' MMMM \'de\' yyyy, HH:mm',

  _keyHome: 'Inicio',
  _keyEnd: 'Fin',
  _keyShift: '⇧ Mayús',
  _keyAlt: 'Alt',
  _keyControl: '✲ Ctrl',

  pageNotFound: 'No se pudo encontrar la página que solicitó.',

  accountApiAuthKey: 'Clave de autorización de API',
  accountAuthorities: 'Permisos',
  accountEmail: 'Dirección de correo electrónico',
  accountFirstName: 'Nombre',
  accountLanguage: 'Idioma',
  accountLastName: 'Apellido',
  accountPassword: 'Contraseña',
  accountPasswordRetype: 'Repetir contraseña',
  accountScopes: 'Ámbitos',
  accountTitle: 'Tratamiento',
  accountTitleMr: 'Sr.',
  accountTitleMs: 'Sra.',
  accountUsername: 'Nombre de usuario',

  actions: 'Acciones',
  actionCancel: 'Cancelar',
  actionClose: 'Cerrar',
  actionCollapse: 'Contraer',
  actionCompare: 'Comparar',
  actionCreate: 'Crear',
  actionDelete: 'Borrar',
  actionDownload: 'Descargar',
  actionDownloadAsZip: 'Descargar como ZIP',
  actionEdit: 'Editar',
  actionEditHotspots: 'Editar áreas activas',
  actionExpand: 'Expandir',
  actionLoad: 'Cargar',
  actionLogIn: 'Iniciar sesión',
  actionLogOut: 'Cerrar sesión',
  actionMove: 'Mover',
  actionOpenInNewWindow: 'Abrir en una ventana nueva',
  actionPageMarkerAdd: 'Añadir marcadores de página',
  actionPageMarkerRemove: 'Eliminar marcadores de página',
  actionPrint: 'Imprimir',
  actionQuantityDecrease: 'Reducir cantidad',
  actionQuantityIncrease: 'Aumentar cantidad',
  actionRedo: 'Rehacer',
  actionRelease: 'Publicar',
  actionRemove: 'Eliminar',
  actionReplace: 'Reemplazar',
  actionReset: 'Restablecer',
  actionSave: 'Guardar',
  actionSend: 'Enviar',
  actionShowAll: 'Mostrar todo',
  actionUndo: 'Deshacer',
  actionUnrelease: 'No publicar',
  actionUpload: 'Subir',
  actionView: 'Mostrar',

  backgroundSizeCenter: 'Centrar',
  backgroundSizeFill: 'Rellenar',
  backgroundSizeRepeat: 'Repetir',
  backgroundSizeStretch: 'Extender',

  bookmarks: 'Marcador',
  bookmarksAdded: 'Página {{page}} añadida a los marcadores.',
  bookmarksNone: 'Aún no tiene marcadores. Abra la vista general de páginas y haga clic en el icono de <x id="START_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;mat-icon&gt;"/>bookmark<x id="CLOSE_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;/mat-icon&gt;"/> para agregar una página a sus marcadores.',
  bookmarksRemoved: 'Se ha eliminado la página {{page}} de marcadores.',

  browserDimensions: 'ngpages no es compatible con este tamaño de ventana. Cambie el tamaño de la ventana.',
  browserError1: 'Su navegador no es compatible actualmente con ngpages.',
  browserError2: 'Utilice uno de nuestros navegadores compatibles.',

  config: 'Configuración',
  configAnimateCorners: 'Animar esquinas',
  configAnimateHotspots: 'Animar áreas activas',
  configBackgroundImage: 'Imagen de fondo',
  configBackgroundSize: 'Tamaño de fondo',
  configCornerSize: 'Tamaño de esquina (px)',
  configCoverPage: 'Portada (solo apps)',
  configCurrency: 'Moneda',
  configCustomCSS: 'CSS propia',
  configCustomJS: 'JavaScript propio',
  configDesign: 'Diseño',
  configDocumentMargin: 'Margen del documento (px)',
  configDoublePage: 'Doble página',
  configFeatures: 'Características',
  configHideToolbar: 'Ocultar barra de herramientas',
  configIndexing: 'Indización de motores de búsqueda',
  configLayout: 'Disposición',
  configLoader: 'Precargar imágenes',
  configMenuPosition: 'Posición del menú',
  configMiscellaneous: 'Otros',
  configPageFlipAnimation: 'Efecto de hojear',
  configPageFlipSound: 'Sonido de hojear',
  configPageMarkerWidth: 'Ancho del marcador de página (px)',
  configPrimaryBackgroundColor: 'Color de fondo 1',
  configPrimaryForegroundColor: 'Color de texto 1',
  configSecondaryBackgroundColor: 'Color de fondo 2',
  configSecondaryForegroundColor: 'Color de texto 2',
  configShop: 'Tienda',
  configShopArticleRegExp: 'Regex de número de artículo',
  configShopEndpoint: 'Extremo',
  configSidePanelPosition: 'Posición del panel lateral',
  configToolbarPosition: 'Posición de la barra de herramientas',
  configTracking: 'Seguimiento',
  configTransparency: 'Transparencia',
  configVat: 'Impuesto sobre el valor añadido (%)',
  configVatIncluded: 'IVA incluido',

  download: 'Descargar',
  downloadAllPages: 'Todas las páginas',
  downloadPages: 'Páginas',
  downloadPagesPlaceholder: 'p. ej., 1-5, 8, 11-13',
  downloadSelectedPages: 'Páginas seleccionadas',

  editorInfoSelect: 'Dibuje un rectángulo para seleccionar las áreas activas dentro de él.',
  editorInfoDrawRect: 'Pulse el botón izquierdo del ratón para empezar a dibujar un área activa rectangular y suéltelo para completarla.',
  editorInfoDrawCircle: 'Pulse el botón izquierdo del ratón para empezar a dibujar un área activa circular y suéltelo para completarla.',
  editorInfoDrawEllipse: 'Pulse el botón izquierdo del ratón para empezar a dibujar un área activa elíptica y suéltelo para completarla.',
  editorInfoDrawPolygon1: 'Pulse el botón izquierdo del ratón para dibujar una nueva área activa poligonal o para agregar un punto al polígono.',
  editorInfoDrawPolygon2: 'Para completar el polígono, pulse el botón derecho del ratón o <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>⏎ Entrar<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>.',
  editorInfoDraw1: 'Mientras dibuja un área activa, pulse <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>Esc<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> para cancelar.',
  editorInfoDraw2: 'Mientras dibuja un área activa, puede mantener pulsada la tecla <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>⇧ Mayús<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> para alinearla con la cuadrícula.',
  editorInfoDraw3: 'Haga clic en un área activa para seleccionarla.<x id="LINE_BREAK" ctype="lb" equiv-text="&lt;br/&gt;"/>Puede seleccionar varias áreas activas mientras mantiene presionada la tecla <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>, y puede seleccionar todas las áreas activas pulsando <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>A<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>.',
  editorInfoDraw4: 'Puede copiar las áreas activas seleccionadas con <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>C<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> y pegarlas con <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>✲ Ctrl<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> + <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>V<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/>.',
  editorInfoDraw5: 'Pulse <x id="START_TAG_KBD" ctype="x-kbd" equiv-text="&lt;kbd&gt;"/>Supr<x id="CLOSE_TAG_KBD" ctype="x-kbd" equiv-text="&lt;/kbd&gt;"/> para eliminar las áreas activas seleccionadas.',
  editorInfoDelete: 'Haga clic en un área activa para eliminarla.',

  editorGrid: 'Cuadrícula',
  editorGridColor: 'Color de la cuadrícula',
  editorGridSize: 'Tamaño de cuadrícula (px)',
  editorGridVisibility: 'Mostrar cuadrícula',
  editorGridSnapToGrid: 'Ajustar a la cuadrícula',

  editorTools: 'Herramientas',
  editorToolDraw: 'Dibujar',
  editorToolSelect: 'Seleccionar',
  editorToolDelete: 'Borrar',

  feedback: 'Comentarios',
  feedbackAdditionalInfo: 'Cuéntenos un poco más (opcional)',
  feedbackCategoryGeneral: 'Generalidades',
  feedbackCategoryUi: 'Interfaz de usuario',
  feedbackConfirmation: '¡Gracias por ayudarnos a mejorar ngpages!',
  feedbackEmailAddress: 'Su dirección de correo electrónico (opcional)',
  feedbackError: 'No se han podido enviar sus comentarios. Inténtelo de nuevo más tarde.',
  feedbackSelectCategory: 'Seleccione una categoría',

  fullscreen: 'Pantalla completa',

  help: 'Ayuda',
  helpGestureMenu: 'Deslice el dedo hacia abajo para abrir el menú y hacia arriba para cerrarlo.',
  helpGestureNav: 'Deslice el dedo hacia la derecha o hacia la izquierda para navegar entre páginas.',
  helpGestureZoom: 'Toque dos veces la pantalla para ampliar la vista.',
  helpKeyboardShortcuts: 'Atajo de teclado',
  helpNavigation: 'Navegación',
  helpPanelsAndDialogs: 'Paneles y diálogos',
  helpZoom: 'Zoom',

  home: 'Inicio',
  homeIntro: 'Arrastre un archivo PDF a la ventana o haga clic en el botón para crear una nueva publicación.',
  homeLetsRumble: '¡Adelante!',
  homeRequirements: 'Requisitos para su PDF',
  homeWelcome: '¡Bienvenido a las nuevas ngpages!',

  hotspots: 'Áreas activas',
  hotspotCount: '<x id="INTERPOLATION" equiv-text="{{editor.hotspots.length}}"/> áreas activas',
  hotspotCountSelected: '<x id="INTERPOLATION" equiv-text="{{editor.selectedHotspots.length}}"/> áreas activas seleccionadas',
  hotspotCountCopied: '<x id="INTERPOLATION" equiv-text="{{editor.copiedHotspots.length}}"/> áreas activas en el portapapeles',

  hotspotActionTypeArticle: 'Artículo',
  hotspotActionTypeAudio: 'Audio',
  hotspotActionTypeExternal: 'Enlace externo',
  hotspotActionTypeFeature: 'Característica',
  hotspotActionTypeGallery: 'Galería',
  hotspotActionTypeIframe: 'iFrame',
  hotspotActionTypeImage: 'Imagen',
  hotspotActionTypeInfo: 'Información',
  hotspotActionTypeInternal: 'Enlace interno',
  hotspotActionTypeMulti: 'Varios',
  hotspotActionTypeNone: 'Seleccione',
  hotspotActionTypeVideo: 'Vídeo',

  hotspotDisplayTypeBorder: 'Marco',
  hotspotDisplayTypeBox: 'Caja',
  hotspotDisplayTypeHidden: 'Oculto',
  hotspotDisplayTypeHover: 'Desplazar puntero por encima',
  hotspotDisplayTypeIcon: 'Icono',
  hotspotDisplayTypeInline: 'Alineado',
  hotspotDisplayTypeMulti: 'Varios',
  hotspotDisplayTypeNone: 'Seleccione',

  hotspotProperties: 'Propiedades del área activa',
  hotspotPropertyType: 'Tipo',
  hotspotPropertyActionInternal: 'Número de página',
  hotspotPropertyActionExternal: 'URL',
  hotspotPropertyActionVideo: 'URL (MP4, WebM, Ogg, YouTube, Vimeo, Dailymotion)',
  hotspotPropertyActionAudio: 'URL (MP3, WAV, Ogg)',
  hotspotPropertyActionArticle: 'Número de artículo',
  hotspotPropertyActionDefault: 'Acción',
  hotspotPropertyTitle: 'Título',
  hotspotPropertyDescription: 'Descripción',
  hotspotPropertyDisplayType: 'Tipo de representación',
  hotspotPropertyColor: 'Color',
  hotspotPropertyAutoplay: 'Reproducción automática',
  hotspotPropertyPrice: 'Precio',
  hotspotPropertyPosition: 'Posición',
  hotspotPropertyPositionX: 'X (px)',
  hotspotPropertyPositionY: 'Y (px)',
  hotspotPropertyDimensions: 'Dimensiones',
  hotspotPropertyDimensionWidth: 'Ancho (px)',
  hotspotPropertyDimensionHeight: 'Alto (px)',

  hotspotShapes: 'Formas',
  hotspotShapeRect: 'Rectángulo',
  hotspotShapeCircle: 'Círculo',
  hotspotShapeEllipse: 'Elipse',
  hotspotShapePolygon: 'Polígono',

  info: 'Información',
  infoCopyright1: 'Copyright © 2019 wissenswerft GmbH',
  infoCopyright2: 'ngpages es un producto de wissenswerft GmbH.',
  infoTechnicalQuestions: 'Si tiene alguna pregunta técnica, no dude en ponerse en contacto con nosotros: <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>support@page2flip.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',
  infoVisitUs: 'Visítenos en internet: <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>www.page2flip.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/> o <x id="START_LINK_1" ctype="x-a" equiv-text="&lt;a&gt;"/>www.wissenswerft.net<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>',

  legal: 'Menciones legales',
  legalImprint: 'Aviso legal',
  legalPrivacyPolicy: 'Política de privacidad',
  legalTermsOfService: 'Condiciones de uso',

  loading: 'Cargando...',
  loadingPublication: 'Cargando publicación...',
  loadingPreview: 'Cargando vista previa...',

  loginInstance: 'Instancia de space.one',
  loginUsername: 'Nombre de usuario',
  loginPassword: 'Contraseña',
  loginStayLoggedIn: 'Mantener sesión iniciada',

  menu: 'Menú',

  navigationFirstPage: 'Primera página',
  navigationLastPage: 'Última página',
  navigationNextPage: 'Próxima página',
  navigationPage: 'Página',
  navigationPageBack: 'Retroceder página',
  navigationPreviousPage: 'Página anterior',

  notes: 'Notas',
  notesNone: 'Aún no tiene notas. Abra la vista general de páginas y haga clic en el icono de <x id="START_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;mat-icon&gt;"/>comment<x id="CLOSE_TAG_MAT-ICON" ctype="x-mat-icon" equiv-text="&lt;/mat-icon&gt;"/> para añadir notas a una página.',
  notesPlaceholder: 'Sus notas',
  notesRemoved: 'Se han eliminado las notas de la página {{page}}.',
  notesSaved: 'Notas guardadas para la página {{page}}.',
  notesTitle: 'Notas para la página <x id="INTERPOLATION" equiv-text="{{pageNumber}}"/>',

  offlineAvailability: 'Disponibilidad sin conexión (solo apps)',

  pageComparison: 'Comparación de páginas',
  pageComparisonError: 'Introduzca un número de página válido.',
  pageComparisonLeftPage: 'Lado izquierdo',
  pageComparisonRightPage: 'Lado derecho',
  pageComparisonWarning: 'La comparación de páginas no está disponible si el efecto de hojear está activado.',

  pageFlipAnimationHint: 'El efecto de hojear solo está disponible en la vista de escritorio.',
  pageFlipAnimationWarning: 'El efecto de hojear no está disponible si la comparación de páginas está activada.',

  pageOverview: 'Vista general de páginas',
  leftPage: 'Lado izquierdo',
  rightPage: 'Lado derecho',

  pageMarkers: 'Marcadores de página',
  pageMarkerColor: 'Color del marcador',
  pageMarkerLabel: 'Etiqueta',
  pageMarkerLabelColor: 'Color del texto',
  pageMarkerNew: 'Nuevo marcador de página',
  pageMarkerPage: 'Página',
  pageMarkerWarning: 'Los marcadores de página no están disponibles si el efecto de hojear está activado.',

  paginatorItemsPerPageLabel: 'Entradas por página:',
  paginatorNextPageLabel: 'Próxima página',
  paginatorPreviousPageLabel: 'Página anterior',
  paginatorFirstPageLabel: 'Primera página',
  paginatorLastPageLabel: 'Última página',
  paginatorRangeOfLabel: 'de',

  positionBottom: 'abajo',
  positionLeft: 'a la izquierda',
  positionRight: 'a la derecha',
  positionTop: 'arriba',

  publication: 'Publicación',
  publications: 'Publicaciones',
  publicationsLatest: 'Sus publicaciones más recientes',
  publicationAuthor: 'Autor',
  publicationCover: 'Cubierta',
  publicationDateCreated: 'Fecha de creación',
  publicationDateModified: 'Fecha de edición',
  publicationDatePublished: 'Fecha de publicación',
  publicationDelete: 'Eliminar publicación',
  publicationDeleteMessage: '¿Seguro que desea eliminar esta publicación? Tenga en cuenta que esta acción no se puede deshacer.',
  publicationDetails: 'Detalles',
  publicationDetailsEdit: 'Editar detalles',
  publicationDescription: 'Descripción',
  publicationHotspots: 'Áreas activas',
  publicationKeywords: 'Palabras clave',
  publicationLanguage: 'Idioma',
  publicationPages: 'Páginas',
  publicationTitle: 'Título',

  releaseApp: 'App',
  releaseDownload: 'Descargar',
  releaseOnline: 'En línea',
  releasePreview: 'Vista previa',

  search: 'Buscar',
  searchClear: 'Borrar término de búsqueda',
  searchFoundMatches: '<x id="INTERPOLATION" equiv-text="{{searchResults.length}}"/> páginas encontradas.',
  searchNoMatches: 'No se han encontrado páginas. Introduzca al menos 3 caracteres.',
  searchMatches: '<x id="INTERPOLATION" equiv-text="{{searchResult.matches}}"/> resultados',
  searchPlaceholder: '¿Qué está buscando?',

  settings: 'Ajustes',

  share: 'Compartir',
  shareCurrentPage: 'Compartir la página actual',
  shareSocialMedia: 'Redes sociales',
  shareUrl: 'URL',

  shopAdditionalInfo: 'Información adicional ✎',
  shopItemNumber: 'N.º art.',
  shopVatExcluded: 'sin {{vat}} % de IVA.',
  shopVatIncluded: 'con {{vat}} % de IVA.',

  shoppingCart: 'Carrito de la compra',
  shoppingCartAdd: 'Añadir al carrito de la compra',
  shoppingCartAdded: 'Se ha añadido {{title}} al carrito de la compra.',
  shoppingCartClear: 'Vaciar carrito de la compra',
  shoppingCartCleared: 'Se ha vaciado el carrito de la compra.',
  shoppingCartEmpty: 'Aún no tiene ningún artículo en el carrito de la compra.',
  shoppingCartPayNow: 'Pagar ahora',
  shoppingCartRemoved: 'Se ha eliminado {{title}} del carrito de la compra.',
  shoppingCartSum: 'Total',

  tableOfContents: 'Tabla de contenido',
  tableOfContentsToggle: 'Expandir/contraer todas las entradas',

  uploaderConfirm: '¿Desea subir este archivo?',
  uploaderErrorFileCount: 'Solo se puede subir un archivo PDF a la vez.',
  uploaderErrorFileType: 'Solo se pueden subir archivos PDF.',
  uploaderFileDate: 'Fecha de modificación: <x id="INTERPOLATION" equiv-text="{{file.lastModified | date:dateFormat}}"/>',
  uploaderFileDrop: 'Coloque su archivo PDF aquí.',
  uploaderFileSize: 'Tamaño de archivo: <x id="INTERPOLATION" equiv-text="{{file.size / 1024 / 1024 | number}}"/> MB',
  uploaderUploading: 'Espere mientras subimos su archivo.',

  viewerPreview: 'Vista previa',
  viewerPreviewDesktop: 'Escritorio',
  viewerPreviewPhone: 'Smartphone',
  viewerPreviewTablet: 'Tableta',
  viewerPreviewWarning: 'La vista previa puede diferir de la representación real.',

  watchList: 'Lista de observación',
  watchListAdd: 'Añadir a la lista de observación',
  watchListAdded: 'Se ha añadido {{title}} a la lista de observación.',
  watchListClear: 'Eliminar lista de observación',
  watchListCleared: 'Lista de observación eliminada.',
  watchListEmpty: 'Aún no tiene ningún artículo en la lista de observación.',
  watchListExists: '{{title}} ya está en la lista de observación.',
  watchListRemoved: 'Se ha eliminado {{title}} de la lista de observación.',

  wizardAnalysis: 'Evaluación',
  wizardAnalysisDescription: 'Estadísticas y conclusiones',
  wizardDocument: 'Documento',
  wizardDocumentDescription: 'Vista general y metadatos',
  wizardHotspots: 'Áreas activas',
  wizardHotspotsDescription: 'Crear y editar',
  wizardPublication: 'Publicación',
  wizardPublicationDescription: 'Publicar y compartir',
  wizardViewer: 'Viewer',
  wizardViewerDescription: 'Configuración y vista previa',

  zoom: 'Zoom',
  zoomError: 'Toque dos veces la pantalla para hacer zoom.',
  zoomIn: 'Ampliar zoom',
  zoomJumpBottom: 'Saltar hacia abajo',
  zoomJumpLeft: 'Saltar a la izquierda',
  zoomJumpRight: 'Salta a la derecha',
  zoomJumpTop: 'Saltar hacia arriba',
  zoomMoveDown: 'Mover hacia abajo',
  zoomMoveLeft: 'Mover a la izquierda',
  zoomMoveRight: 'Mover a la derecha',
  zoomMoveTop: 'Mover hacia arriba',
  zoomOut: 'Reducir zoom',
  zoomReset: 'Restablecer zoom',

  // vw-workshop-equipment specific translations

  vw_actionCreateCatalog: 'Crear catálogo',
  vw_actionCreateNewCatalog: 'Crear un nuevo catálogo',
  vw_actionReset: 'Empezar desde el principio',
  vw_actionDownloadCatalog: 'Descargar el catálogo',
  vw_catalogLanguage: 'Idioma del catálogo',
  vw_catalogPreview: 'Vista previa del catálogo',
  vw_catalogCurrency: 'Divisa',
  vw_imprint: 'Aviso legal',
  vw_intro1: 'Bienvenido al Creador del catálogo de ventas Equipamiento de taller de Volkswagen AG. Aquí podrá crear fácilmente su catálogo específico de mercado en solo unos pocos pasos. Para ello recomendamos utilizar los navegadores Edge, Firefox o Chrome.',
  vw_intro2: 'Es así de sencillo:',
  vw_intro3: 'Después de unos 30 minutos, el proceso de creación ha terminado y es posible hojear el catálogo, descargarlo como un paquete ZIP o reiniciar el proceso de creación.',
  vw_intro4: 'Si tiene alguna pregunta sobre el catálogo, deseos o ideas para mejorarlo, esperamos recibir sus comentarios en <x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>Workshop.Equipment@volkswagen.de<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>.',
  vw_intro_list1: 'Descargue la plantilla de la lista de precios en su PC. Se trata de un archivo CSV con la gama completa de productos incluidos en el catálogo, las especificaciones de precio de coste comercial (PCC) y PVP recomendado conformes con la estrategia y las normas de la UE de las marcas del Grupo. Los productos con facturación directa se pueden encontrar en la lista con la indicación «<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>on request<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>».',
  vw_intro_list11: '<x id="START_LINK" ctype="x-a" equiv-text="&lt;a&gt;"/>Descargar plantilla de lista de precios (precios del 01.01.2021, estándares del 01.01.2021)<x id="CLOSE_LINK" ctype="x-a" equiv-text="&lt;/a&gt;"/>',
  vw_intro_list2: 'Abra el archivo CSV en el programa Excel.',
  vw_intro_list3: 'Introduzca sus precios específicos del mercado en las columnas PCC y PVP recomendado. Si no desea especificar un precio o no ofrecer un producto en su mercado, simplemente puede introducir «<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>on request<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>» o «<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>not available<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>» en el campo del precio.',
  vw_intro_list4: 'Añada su información sobre el estándar en las columnas VW, Audi, SEAT, SKODA, VWN y Porsche. Introduzca un «<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>0<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>» si el producto no es estándar de la marca correspondiente o introduzca un «<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>1<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>» si es estándar de la marca.',
  vw_intro_list5: 'No cambie las entradas de las columnas «<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Product<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>» (Descripción del producto) y «<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Article-number<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>» (Número de pieza) para garantizar un buen funcionamiento Tampoco elimine ni agregue columnas o filas, ni cambie los encabezados de columna.',
  vw_intro_list6: 'Guarde su lista de precios como un archivo CSV localmente en su PC.',
  vw_intro_list7: 'Cargue su lista de precios mediante el botón «<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Seleccionar archivo<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>» de abajo o arrastrando el archivo CSV hasta el cuadro gris.',
  vw_intro_list8: 'Introduzca la información de la página de título: su mercado, el tipo de precio (PCC o PVP recomendado) y la fecha de los precios (fecha de validez de la información de los precios).',
  vw_intro_list9: 'Seleccione el idioma del catálogo.',
  vw_intro_list10: 'Inicie el proceso de creación mediante el botón «<x id="START_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;i&gt;"/>Crear catálogo<x id="CLOSE_ITALIC_TEXT" ctype="x-i" equiv-text="&lt;/i&gt;"/>».',
  vw_legalNotice: 'Menciones legales',
  vw_loginError: 'Contraseña incorrecta.',
  vw_loginMessage: 'Inicie sesión para continuar.',
  vw_market: 'Mercado',
  vw_priceDate: 'Fecha de los precios',
  vw_priceList: 'Lista de precios',
  vw_priceType: 'Tipo de precio',
  vw_priceTypeDNP: 'DNP',
  vw_priceTypeRRP: 'RRP',
  vw_stateConverting: 'Se está creando su catálogo. Esto puede llevar algún tiempo.',
  vw_stateFailed: 'Se ha producido un error. Inténtelo de nuevo.',
  vw_stateUploading: 'Se están subiendo los datos. Espere un momento mientras comenzamos el proceso de creación.',
  vw_stateWaiting: 'El catálogo se ha agregado a la cola.',
  vw_title: 'Workshop Equipment Catalogue Creator',
  vw_uploaderMsgClickOrDragFile: 'o arrastre el archivo CSV aquí.',
  vw_uploaderMsgDropFile: 'Colocar el archivo aquí.',
  vw_uploaderMsgFileCountError: 'Solo se puede subir un archivo.',
  vw_uploaderMsgFileTypeError: 'Solo se pueden subir archivos CSV.'

};
