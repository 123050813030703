import { Component, Input } from '@angular/core';

/**
 * Component that represents the loading animation of the uploader.
 */
@Component({
  selector: 'vw-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent {

  /** Percentage of the loader. */
  @Input() percentage: number;

}
