import { Component } from '@angular/core';

/**
 * Component that represents the admin view.
 */
@Component({
  selector: 'vw-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent {

  /** Whether or not the user is authenticated. */
  authenticated = false;

  /**
   * Constructor of the component.
   */
  constructor() {}

}
